import { Components, linearProgressClasses, Theme } from '@mui/material';

export const overrideMuiLinearProgress = (theme: Theme): Components['MuiLinearProgress'] => {
    return {
        styleOverrides: {
            root: {
                background: 'inherit',
                borderRadius: theme.shape.borderRadius,
                height: 10,

                [`& .${linearProgressClasses.bar1Determinate}`]: {
                    borderRadius: 4,
                    zIndex: 1,
                },

                ':after': {
                    display: 'block',
                    content: "''",
                    position: 'absolute',
                    width: '100%',
                    height: '30%',
                    background: theme.palette.grey[500],
                    top: '50%',
                    transform: 'translateY(-50%)',
                    borderRadius: '10px',
                    zIndex: 0,
                }
            },
        }
    };
}; 