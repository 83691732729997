import { alpha, buttonClasses, Components, iconButtonClasses, Theme } from '@mui/material';

export const overrideMuiButton = (theme: Theme): Components['MuiButton'] => {
    return {
        variants: [
            {
                props: { variant: 'text' },
                style: {
                    '&: hover': {

                        backgroundColor: 'transparent'
                    }
                },
            },
            {
                props: {variant: 'outlined'},
                style: {
                    borderSize: 2,
                }
            },
        ],
        styleOverrides: {
            root: {
                textTransform: 'unset',

                [`&.${buttonClasses.disabled}`]: {
                    backgroundColor: theme.palette.grey[500],
                    color: theme.palette.grey[300],
                },
            },
            sizeLarge: {
                padding: theme.spacing(2, 3),
                borderRadius: theme.shape.borderRadius * 2,
                fontWeight: theme.typography.fontWeightMedium,
            },
        }
    };
};

export const overrideMuiIconButton = (): Components['MuiIconButton'] => {
    return {
        styleOverrides: {
            colorInherit: {
                [`&.${iconButtonClasses.disabled}`]: {
                    color: alpha('#fff', .4)
                }
            }
        }
    };
};