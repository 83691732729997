import { globalQueryClient } from '../../GlobalQueryClientProvider';
import { useQuery } from '@tanstack/react-query';
import { UsersLogic } from '../../Logic';

export const usersQueryKey = 'users';
const staleTimeInSeconds = 60;

export default function useUsersQuery() {
    const usersQuery = useQuery({
        queryKey: [usersQueryKey],
        queryFn: UsersLogic.fetchAllUsers,
        refetchOnWindowFocus: false,
        staleTime: staleTimeInSeconds * 1000,
    });

    function invalidateUsersQuery() {
        void globalQueryClient.invalidateQueries({ queryKey: [usersQueryKey]});
    }

    return {
        usersQuery,
        invalidateUsersQuery,
    };
} 
