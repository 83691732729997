import { motion } from 'framer-motion';

const CircleAnimation = {
    visible: {
        scale: 1,
        transition: {
            type: 'just',
        }
    },
    hidden: {
        scale: 0,
    },
};

const ArrowGroup = {
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            duration: .18,
            delay: .69,
        }
    },

    hidden: {
        opacity: 0,
        scale: 1.3,
    },
};

export default function UploadImageEmptyState() {
    return (
        <motion.g>
            <motion.circle
                initial='hidden'
                animate='visible'
                variants={CircleAnimation}
                transform-origin='232.47000122070312px 211.5px'
                r='211.5' cy='211.5' cx='232.47'
                fill='#1a1a1a'
                data-name='Ellipse 89'
                id='Ellipse_89'
            />

            <motion.g
                initial='hidden'
                animate='visible'
                variants={ArrowGroup}
                id='svg_1'
                transform-origin='232.47499084472656px 211.50000762939453px'
            >
                <path
                    fill='#313132'
                    d='m106.28,64.22l-43.64,0c-3.19,0 -5.78,2.59 -5.78,5.78l0,43.64l14.25,0l0,-35.16l35.16,0l0,-14.25l0.01,-0.01z'
                    data-name='cls-2'
                    id='cls-2'
                />
                <path
                    fill='#313132'
                    d='m56.87,309.37l0,43.64c0,3.19 2.59,5.78 5.78,5.78l43.64,0l0,-14.25l-35.16,0l0,-35.16l-14.25,0l-0.01,-0.01z'
                    data-name='cls-2'
                    id='cls-2'
                />
                <path
                    fill='#313132'
                    d='m358.67,358.78l43.64,0c3.19,0 5.78,-2.59 5.78,-5.78l0,-43.64l-14.25,0l0,35.16l-35.16,0l0,14.25l-0.01,0.01z'
                    data-name='cls-2'
                    id='cls-2'
                />
                <path
                    fill='#313132'
                    d='m408.08,113.63l0,-43.64c0,-3.19 -2.59,-5.78 -5.78,-5.78l-43.64,0l0,14.25l35.16,0l0,35.16l14.25,0l0.01,0.01z'
                    data-name='cls-2'
                    id='cls-2'
                />
            </motion.g>

            <motion.path
                fill='#ffffff'
                stroke='#000000'
                strokeLinejoin='round'
                strokeLinecap='round'
                strokeWidth='2'
                d='m112.88696,275.2803l0,-100.98546c0,-14.8834 0,-22.32523 2.89656,-28.00991c2.54776,-5.00051 6.61322,-9.06597 11.61373,-11.61373c5.68468,-2.89656 13.12652,-2.89656 28.00991,-2.89656l14.01359,0c1.63357,0 2.45036,0 3.2039,-0.08637c3.93179,-0.45032 7.45817,-2.62974 9.61926,-5.94499c0.41417,-0.63541 0.77958,-1.36609 1.51,-2.82706c1.4611,-2.92207 2.19165,-4.38317 3.02013,-5.65399c4.32218,-6.63049 11.37495,-10.98934 19.23879,-11.89011c1.50681,-0.17261 3.13985,-0.17261 6.40726,-0.17261l40.10983,0c3.26741,0 4.90045,0 6.40726,0.17261c7.86358,0.90076 14.91662,5.25962 19.23906,11.89011c0.82782,1.27069 1.55863,2.73232 3.02026,5.65399c0.72949,1.4611 1.09489,2.19165 1.50947,2.82706c2.16189,3.31525 5.68708,5.49467 9.61887,5.94499c0.75473,0.08637 1.57059,0.08637 3.20496,0.08637l14.01306,0c14.8834,0 22.32576,0 28.01018,2.89656c5.00011,2.54776 9.0661,6.61322 11.61333,11.61373c2.89669,5.68468 2.89669,13.12652 2.89669,28.00991l0,100.98546c0,14.8834 0,22.32576 -2.89669,28.01018c-2.54723,5.00011 -6.61322,9.0661 -11.61333,11.61333c-5.68442,2.89669 -13.12678,2.89669 -28.01018,2.89669l-154.1357,0c-14.8834,0 -22.32523,0 -28.00991,-2.89669c-5.00051,-2.54723 -9.06597,-6.61322 -11.61373,-11.61333c-2.89656,-5.68442 -2.89656,-13.12678 -2.89656,-28.01018z'
                animate={{
                    scale: [0, 1, 1, 1, .78, 1],
                }}
                transition={{
                    delay: .4,
                    duration: 1.1,
                    ease: 'easeInOut',
                    times: [0, 0.2, 0.5, 0.8, 1],
                    repeat: 0,
                }}
            />

            <motion.circle
                transform-origin='232.47000122070312px 211.5px'
                r='41' cy='220' cx='232.47'
                fill='#1a1a1a'
                data-name='Ellipse 89'
                id='Ellipse_89'
                animate={{
                    scale: [0, 1, 1, 1, .78, 1],
                }}
                transition={{
                    delay: .4,
                    duration: 1.1,
                    ease: 'easeInOut',
                    times: [0, 0.2, 0.5, 0.8, 1],
                    repeat: 0,
                }}
            />

            <motion.circle
                transform-origin='232.47000122070312px 211.5px'
                r='30' cy='220' cx='232.47'
                fill='#fff'
                data-name='Ellipse 89'
                id='Ellipse_89'
                animate={{
                    scale: [0, 1, 1, 1, .78, 1],
                }}
                transition={{
                    delay: .4,
                    duration: 1.1,
                    ease: 'easeInOut',
                    times: [0, 0.2, 0.5, 0.8, 1],
                    repeat: 0,
                }}
            />
        </motion.g>
    );
}