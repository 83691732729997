import { OnboardingNavigateNextButton } from '../Components';
import { PageImageContainer, PersonIcon, TextWithColor } from '../../../../Components';
import { styled, Typography } from '@mui/material';
import { usePageTitle } from '../../../../Hooks';
import theme from '../../../../Theme';

const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(3, 0)
}));

export default function OnboardingAnnieIntroPage() {
    usePageTitle('Ontmoet Annie - Onboarding');

    return (
        <>
            <PageImageContainer backgroundcolor={theme.palette.primary.main}>
                <PersonIcon sx={{ color: theme.palette.grey[800], fontSize: 128 }} />
            </PageImageContainer>

            <Root>
                <Typography variant='h2'>Ontmoet Annie</Typography>
                <Typography sx={{ margin: theme.spacing(1, 0) }} variant='body1'>Bij JEM-id heb je een eigen coach, <TextWithColor color={theme.palette.primary.main}>Annie</TextWithColor>. Annie houdt je scherp en fris, en maakt van jou een product professional in no-time!</Typography>
            </Root>

            <OnboardingNavigateNextButton />
        </>
    );
}