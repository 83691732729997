import { Components, Theme } from '@mui/material';

export const overrideMuiAvatar = (theme: Theme): Components['MuiAvatar'] => {
    return {
        styleOverrides: {
            root: {
                borderRadius: theme.shape.borderRadius * 2,
            },
        }
    };
};