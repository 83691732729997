import { Logo } from '../../../../Components';
import { styled } from '@mui/system';
import OnboardingNavigatePreviousButton from './Navigate/OnboardingNavigatePreviousButton';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom } from '../../Logic/onboardingState';

const Root = styled('div')<{ currentStep: number }>(({ theme, currentStep }) => ({
    display: 'inherit',
    alignItems: 'center',
    justifyContent: currentStep === 1 ? 'center' : 'space-between',
    padding: theme.spacing(1, 3, 1, 2),
}));

export default function OnboardingTopBar() {
    const { currentStep } = useAtomValue(onboardingStateAtom);

    return (
        <Root currentStep={currentStep}>
            <OnboardingNavigatePreviousButton />

            <Logo sx={{ fontSize: 72 }} />
        </Root>
    );
}