import { CSSProperties } from 'react';
import { isWinterTime, isTodaySameAsInput } from './DateHelper';
import Confetti from 'react-confetti';
import Snowfall from 'react-snowfall';

const style: CSSProperties = {
    zIndex: 9999,
    height: '100vh',
    width: '100vw',
    position: 'fixed'
};

export function getSpecialTheme(isSmallScreen: boolean, birthDate?: string,) {
    const numberOfPieces = isSmallScreen ? 50 : 100;

    if (birthDate && isTodaySameAsInput(birthDate)) {
        return <Confetti style={style} numberOfPieces={numberOfPieces} />;
    } else if (isWinterTime()) {
        return <Snowfall style={style} snowflakeCount={numberOfPieces} />;
    } else {
        return null;
    }
}
