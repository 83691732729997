import { motion } from 'framer-motion';
import { styled, Typography } from '@mui/material';

interface IProps {
    title?: string,
    subTitle?: string,
}

const Container = styled('div')(() => ({
    paddingTop: '10%', // TODO: maybe use spacing, we don't want to use percentages like this.
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
}));

const VisualContainer = styled(motion.div)(({ theme }) => ({
    position: 'relative',
    margin: theme.spacing(0, 0, 5),
}));

const BarsContainer = styled(motion.div)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}));

const Bar = styled(motion.div)(({ theme }) => ({
    backgroundColor: theme.palette.grey[600],
    width: 220,
    height: 50,
    borderRadius: theme.shape.borderRadius * 2,
    margin: theme.spacing(0, 0, 1.5),
}));

const BarZoom = styled(motion.div)(({ theme }) => ({
    backgroundColor: theme.palette.grey[400],
    width: 280,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    borderRadius: theme.shape.borderRadius * 2,
    margin: theme.spacing(0, 0, 1.5),
}));

const CrossIcon = styled('svg')(() => ({
    width: 24,
}));

const LoupeContainer = styled(motion.svg)(() => ({
    position: 'absolute',
    top: 20, left: '50%',
    width: 82,
    height: 82,
}));

const visual = {
    hidden: {
        opacity: 1,
        scale: 0
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.1,
        }
    }
};

const bar = {
    hidden: {
        scale: 0.5,
        opacity: 0
    },
    visible: {
        scale: 1,
        opacity: 1
    }
};

const loupeContainer = {
    hidden: {
        opacity: 1,
        scale: 0
    },
    visible: {
        opacity: 1,
        scale: 1,
    }
};

export default function NoResults(props: IProps) {
    const {
        title = 'Dit wordt niet gevonden',
        subTitle = 'Het lijkt erop dat jouw zoekterm niet bestaat'
    } = props;

    return (
        <Container>
            <VisualContainer>
                <BarsContainer
                    variants={visual}
                    initial='hidden'
                    animate='visible'
                >
                    <Bar variants={bar} />
                    <BarZoom variants={bar}>
                        <CrossIcon viewBox='0 0 44.3 44.3'>
                            <polygon points='44.3 7.07 37.23 0 22.15 15.08 7.07 0 0 7.07 15.08 22.15 0 37.23 7.07 44.3 22.15 29.22 37.23 44.3 44.3 37.23 29.22 22.15 44.3 7.07' fill='#ababab' />
                        </CrossIcon>
                    </BarZoom>
                    <Bar variants={bar} />
                    <Bar variants={bar} />
                </BarsContainer>
                <LoupeContainer variants={loupeContainer}>
                    <svg viewBox='0 0 81.73 81.73'>
                        <path d='m81.73,76.92l-21.3-21.3c11.91-14.56,9.75-36.02-4.81-47.93C41.06-4.21,19.6-2.06,7.69,12.5S-2.06,48.53,12.5,60.43c12.54,10.26,30.58,10.26,43.12,0l21.3,21.3,4.81-4.81Zm-47.61-15.6c-15.03,0-27.21-12.18-27.21-27.21S19.1,6.91,34.12,6.91c15.03,0,27.21,12.18,27.21,27.21h0c0,15.03-12.18,27.21-27.21,27.21' fill='#ffffff' />
                    </svg>
                </LoupeContainer>
            </VisualContainer>

            <Typography variant='h2' mb={1}>{title}</Typography>
            <Typography variant='body1'>{subTitle}</Typography>
        </Container>
    );
}
