import { DefaultOptions, MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getEnvironment } from './Config';
import { PropsWithChildren, Suspense, lazy } from 'react';
import SnackbarManager from './Components/Snackbar/SnackbarManager';

const defaultQueryOptions: DefaultOptions = {
    queries: {
        refetchOnWindowFocus: false,
    },
};

export const globalQueryClient = new QueryClient({
    defaultOptions: defaultQueryOptions,
    queryCache: new QueryCache({
        onError: error => SnackbarManager.showError(error),
    }),
    mutationCache: new MutationCache({
        onError: error => SnackbarManager.showError(error),
    })
});

const ReactQueryDevtools = lazy(() =>
    import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
        default: d.ReactQueryDevtools,
    })),
);

export default function GlobalQueryClientProvider(props: PropsWithChildren) {
    const enableDevTools = getEnvironment() === 'dev';

    return (
        <QueryClientProvider client={globalQueryClient}>
            {props.children}

            {enableDevTools &&
                <Suspense fallback={null}>
                    <ReactQueryDevtools />
                </Suspense>
            }
        </QueryClientProvider>
    );
}