import { Fab, styled } from '@mui/material';

const MobileFab = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
}));

export default MobileFab;