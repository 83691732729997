import { Shadows, Theme } from '@mui/material';

const overrideShadows = (theme: Theme): Shadows => [
    'none',
    '0 3px 6px rgba(0,0,0,.16)',
    'radial-gradient(ellipse 75% 125% at 50% -25%, rgba(170, 222, 26, 0.33) 0%, rgba(170, 222, 26, 0) 100%)',
    'radial-gradient(ellipse 100% 50% at 50% -25%, rgba(170, 222, 26, 0.33) 0%, rgba(170, 222, 26, 0) 100%)',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
];

export default overrideShadows;