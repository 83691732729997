import { createRoutesFromElements, useNavigate, useRoutes } from 'react-router-dom';
import { memo, PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material';

export interface IDialogProps {
    dialogHeight: number | string,
    fitContentHeight: boolean,
    headerTitle: string | JSX.Element
}

interface IProps {
    DialogComponent: any;
    backPath: string;
    skipSlidingIn?: boolean;
    dialogProps?: Partial<IDialogProps>;
    fullScreen?: boolean;
    /** @deprecated do not use this, it will be removed in a next version */
    onClose?: () => void;
}

function RoutingDialog(props: PropsWithChildren<IProps>) {
    const { DialogComponent, backPath, skipSlidingIn, children, dialogProps, fullScreen = true, onClose } = props;

    const routes = createRoutesFromElements(children);

    const element: ReactElement | null = useRoutes(routes);
    const navigate = useNavigate();

    const theme = useTheme();
    const transitionDuration = theme.transitions.duration.leavingScreen ?? 500;

    const [open, setOpen] = useState(element !== null);
    const [leavingFallbackElement, setLeavingFallbackElement] = useState<typeof element>(null);

    const lastNonNullElement = useRef<typeof element>(null);
    if (element !== null) {
        lastNonNullElement.current = element;
    }

    const leaveClearTimeout = useRef<number>();
    useEffect(() => {
        setOpen(element !== null);
        window.clearTimeout(leaveClearTimeout.current);

        if (element) {
            setLeavingFallbackElement(null);
        } else {
            setLeavingFallbackElement(lastNonNullElement.current);
            lastNonNullElement.current = null;
            leaveClearTimeout.current = window.setTimeout(function () {
                setLeavingFallbackElement(null);
            }, transitionDuration);
        }
    }, [element, transitionDuration]);

    function handleClose() {
        navigate(backPath);
    }

    const renderingElement = element ?? lastNonNullElement.current ?? leavingFallbackElement;

    return (
        <DialogComponent
            skipSlidingIn={skipSlidingIn}
            open={open}
            onClose={onClose ?? handleClose}
            dialogProps={dialogProps}
            fullScreen={fullScreen}
        >
            {renderingElement}
        </DialogComponent>
    );
}

export default memo(RoutingDialog);
