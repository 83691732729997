import { Avatar, Stack, SxProps, Typography } from '@mui/material';
import { IUser, IUserSummary } from '../../Definitions/userDefinitions';
import { StackProps } from '@mui/system';

interface IProps extends StackProps {
    user: IUser | IUserSummary;
    secondary?: string;
    thumbnailSize?: number;
    avatarSx?: SxProps;
}

export default function UserThumbnailWithLabels(props: IProps) {
    const { user, secondary, thumbnailSize = 40, avatarSx, ...restProps } = props;

    return (
        <Stack {...restProps} direction='row' alignItems='center' gap={1} >
            <Avatar
                src={user.profilePicture}
                alt={user.fullName}
                sx={{
                    borderRadius: 9999,
                    width: thumbnailSize,
                    height: thumbnailSize,
                    ...avatarSx,
                }}
            />
            <Stack>
                <Typography color='text.primary'>{user.fullName}</Typography>
                {secondary && <Typography color='grey.300' fontSize={12}>{secondary}</Typography>}
            </Stack>
        </Stack>
    );
}
