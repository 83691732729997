import { Box, Typography } from '@mui/material';
import { IFormInputs } from '../../Onboarding';
import { IUser } from '../../../../Definitions/userDefinitions';
import { OnboardingNavigateNextButton } from '../Components';
import { onboardingStateAtom, useOnboardingState } from '../../Logic/onboardingState';
import { useAtomValue } from 'jotai';
import { useForm } from 'react-hook-form';
import { usePageTitle } from '../../../../Hooks';
import { useState } from 'react';
import theme from '../../../../Theme';
import ErrorMessagesLogic from '../../../../Components/Form/Logic/errorMessagesLogic';
import ImageFormGroup from '../../../../Components/Form/ImageFormGroup/ImageFormGroup';

interface IProfilePicture {
    profilePicture: string | undefined;
    imageToUpload: File | null;
}

export default function OnboardingUploadPhotoPage() {
    const { user } = useAtomValue(onboardingStateAtom);
    const { setOnboardingState, navigateToNextStep } = useOnboardingState();

    const [profilePicture, setProfilePicture] = useState(user.profilePicture ?? '');
    const [isLoading, setIsLoading] = useState(false);

    usePageTitle('Vertel iets over jezelf - Onboarding');

    const { control, handleSubmit } = useForm<IProfilePicture>({
        defaultValues: {
            profilePicture: user.profilePicture ?? ''
        },
        mode: 'onChange',
    });

    async function loadBlobAsString(profilePicture: File | null, updatedUser: IUser) {
        if (profilePicture) {
            const profilePictureBlob = await new Promise((resolve) => {
                const reader = new FileReader();

                reader.readAsDataURL(profilePicture as File);
                reader.onloadend = () => {
                    resolve(reader.result as string);
                };
            });
            updatedUser.profilePicture = profilePictureBlob as string;
            setProfilePicture(profilePictureBlob as string);
        }
    }

    async function handleChangeImage(imageToUpload: File | null) {
        setIsLoading(true);
        const uploadedPhoto = imageToUpload;
        setOnboardingState(prevState => ({ ...prevState, uploadedPhoto }));

        loadBlobAsString(uploadedPhoto, user);
        setIsLoading(false);
    }

    function onSubmit() {
        const navigateData: IFormInputs = {
            address: user.address!,
            birthDate: user.birthDate!,
            city: user.city!,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber!,
            postalCode: user.postalCode!,
            profilePicture: profilePicture,
        };

        navigateToNextStep(navigateData);
    }

    return (
        <>
            <Typography variant='h2'>Iets meer over jou</Typography>
            <Typography variant='body1' sx={{ marginTop: 1 }}>Stel jezelf voor!</Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                    variant='h3'
                    sx={{ margin: theme.spacing(3, 0, 2) }}>
                    Upload jouw foto
                </Typography>

                <Box sx={{ width: { xs: 'unset', md: '100%' } }}>
                    <ImageFormGroup
                        control={control}
                        name='profilePicture'
                        onChangeCallback={handleChangeImage}
                        rules={{
                            minLength: { value: 3, message: ErrorMessagesLogic.getErrorMessageByType('minLength', 3) },
                        }}
                        imageUploadProps={{
                            wrapperSx: {
                                width: '100%',
                                maxWidth: 500,
                                mb: 5,
                            }
                        }}
                    />
                </Box>

                <Typography
                    variant='body1'
                    sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(4) }}>
                    Als je deze stap overslaat kiest Annie een leuke foto 😉
                </Typography>

                <OnboardingNavigateNextButton type='submit' disabled={isLoading} />
            </form>
        </>
    );
}