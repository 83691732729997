import { AddLearningPathTemplate, FileParameter, LearningPathIconType, LearningPathStatus, LearningPathStep, UpdateLearningPathTemplate } from '../../../../../../../../ApiClients/ApiClient';
import { Api } from '../../../../../../../../ApiClients/clients';
import { generateUuid } from '../../../../../../../../Helpers/uuidHelpers';
import { ILearningPath } from '../../../../../../Components/LearningPath/Definitions/learningPathDefinitions';
import IndividualLearningPathLogic from '../../../../../../Components/LearningPath/Individual/Logic/IndividualLearningPathLogic';

export default class TemplateLogic {
    static getEmptyTemplate(): ILearningPath {
        return {
            id: 'new',
            status: LearningPathStatus.Saved,
            iconType: LearningPathIconType.Console,
            steps: [],
            title: '',
            description: '',
            specialties: [],
            eligibleToEdit: true,
            userId: '',
        };
    }

    static getEmptyTemplateStep(): LearningPathStep {
        return {
            id: generateUuid(),
            title: '',
            description: '',
            stepNumber: 0,
        };
    }

    private static mapILearningPathToAddLearningPathTemplate(template: ILearningPath): AddLearningPathTemplate {
        return {
            id: template.id,
            title: template.title,
            description: template.description ?? '',
            iconType: template.iconType,
            specialties: template.specialties ?? [],
            status: template.status ?? LearningPathStatus.Draft,
            steps: template.steps.map(IndividualLearningPathLogic.mapLearningPathStepToAddOrUpdateLearningPathStep)
        };
    }

    private static mapILearningPathToUpdateLearningPathTemplate(template: ILearningPath): UpdateLearningPathTemplate {
        return {
            title: template.title,
            description: template.description ?? '',
            iconType: template.iconType,
            specialties: template.specialties ?? [],
            status: template.status ?? LearningPathStatus.Draft,
            steps: template.steps.map(IndividualLearningPathLogic.mapLearningPathStepToAddOrUpdateLearningPathStep)
        };
    }

    static async addTemplate(template: ILearningPath): Promise<AddLearningPathTemplate> {
        const addTemplate: AddLearningPathTemplate = TemplateLogic.mapILearningPathToAddLearningPathTemplate(template);

        await Api.addLearningPathTemplate(addTemplate);

        return addTemplate;
    }

    static async updateTemplate(template: ILearningPath): Promise<UpdateLearningPathTemplate> {
        const updateTemplate: UpdateLearningPathTemplate = TemplateLogic.mapILearningPathToUpdateLearningPathTemplate(template);

        await Api.updateLearningPathTemplate(template.id, updateTemplate);

        return updateTemplate;
    }

    static async uploadImage(uploading: FileParameter): Promise<string> {
        const resultUrl = await Api.uploadImage(uploading);

        return resultUrl;
    }
}