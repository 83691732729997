import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { Content } from '../Components/Content';
import RoutingDialogTest from '../Modules/Main/Modules/RoutingDIalogTest/RoutingDialogTest';
import AuthenticatedUserHandler from './Components/AuthenticatedUserHandler';

const Home = lazy(() => import(/* webpackChunkName: 'home' */ '../Modules/Main/Modules/Home/Home'));
const HourRegistration = lazy(() => import(/*webpackChunkName: 'hour-registration-v2'*/ '../Modules/Main/Modules/HourRegistrationV2/HourRegistration'));
const Learn = lazy(() => import(/*webpackChunkName: 'learn'*/ '../Modules/Main/Modules/Learn/Learn'));
const Login = lazy(() => import(/* webpackChunkName: 'login' */ '../Modules/Login/Login'));
const Main = lazy(() => import(/* webpackChunkName: 'management' */ '../Modules/Main/Main'));
const Management = lazy(() => import(/* webpackChunkName: 'management' */ '../Modules/Main/Modules/Management/Management'));
const Onboarding = lazy(() => import(/* webpackChunkName: 'onboarding' */ '../Modules/Onboarding/Onboarding'));
const PageNotFound = lazy(() => import(/* webpackChunkName: 'page-not-found' */ './Components/PageNotFound'));
const PracticalInfo = lazy(() => import(/* webpackChunkName: 'practical-info' */ '../Modules/Main/Modules/PracticalInfo/PracticalInfo'));
const Profile = lazy(() => import(/* webpackChunkName: 'profile' */ '../Modules/Main/Modules/Profile/Profile'));
const Teams = lazy(() => import(/* webpackChunkName: 'teams' */ '../Modules/Main/Modules/Teams/Teams'));
const Feedback = lazy(() => import(/* webpackChunkName: 'feedback' */ '../Modules/Main/Modules/Feedback/Feedback'));

function* createAppRoutes(isDeveloper: boolean, hasAccessToFeedback: boolean): Generator<RouteObject> {
    yield { index: true, element: <Home /> };
    yield { path: '/teams/*', element: <Teams /> };
    yield { path: '/practical-info/*', element: <PracticalInfo /> };
    yield { path: '/profile/*', element: <Profile /> };
    yield { path: '/hour-registration/:referenceCode/*', element: <HourRegistration /> };
    yield { path: '/learn/*', element: <Learn /> };
    yield { path: '/management/*', element: <Management /> };

    // TODO: remove hasAccessToFeedback check
    if (hasAccessToFeedback) {
        yield { path: '/feedback/*', element: <Feedback /> };
    }

    if (isDeveloper) {
        yield { path: '/routing-test/*', element: <RoutingDialogTest /> };
    }

    yield { path: '/not-found', element: <PageNotFound /> };
    yield { path: '/*', element: <Navigate to='/not-found' replace /> };
}

function createRoutes(isDeveloper: boolean, hasAccessToFeedback: boolean): RouteObject[] {
    return [
        {
            path: '/',
            element: <AuthenticatedUserHandler />,
            children: [{
                path: '/',
                element: <Main />,
                children: [...createAppRoutes(isDeveloper, hasAccessToFeedback)],
            }]
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/invite/:token',
            element: (
                <Content sx={{ padding: 0 }}>
                    <Onboarding />
                </Content>
            )
        }
    ];
}

export default createRoutes;
