import { styled } from '@mui/material';

interface IBubbleProps {
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
    size: number | string;
    top?: number | string;
}

const Bubble = styled('div')<IBubbleProps>(({ size, top, left, bottom, right }) => ({
    background: 'rgba(0, 0, 0, .1)',
    borderRadius: 9999,
    bottom,
    height: size,
    left,
    position: 'absolute',
    right,
    top,
    width: size,
    zIndex: 0,
}));

export default Bubble;