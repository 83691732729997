import { Autocomplete, autocompleteClasses, debounce, styled, useTheme } from '@mui/material';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { FormGroupErrorText } from '.';
import { IFormGroupProps } from './Definitions/formGroupDefinitions';
import { StyledTextfield } from './DefaultFormGroup';
import { useEffect, useMemo, useState } from 'react';
import FormGroupLabel from './FormGroupLabel';
import ObjectHelper from '../../Helpers/ObjectHelper';
import PopperComponent from './PopperComponent';

interface IProps<T, S extends FieldValues> extends IFormGroupProps<S> {
    data: T[];
    label?: string;
    optionKey: keyof T;
    placeholder?: string;
    secondOptionKey?: keyof T;
    noOptionsText?: string;
    noMargin?: boolean;
    onFetchSuggestions?: (value: string) => Promise<void>;
    onChangeCallback?: (value: any) => void;
    popupIcon?: JSX.Element;
    autoFocus?: boolean;
}

// TODO: fix for all inputs
const StyledInputRoot = styled('div')<{ noMargin: boolean, noOptions: boolean }>(({ theme, noMargin, noOptions }) => ({
    paddingBottom: noMargin ? 0 : theme.spacing(2),

    [`& .${autocompleteClasses.noOptions}`]: {
        display: noOptions ? 'block' : 'none',
        color: theme.palette.text.primary
    },
}));

export default function AutoCompleteFormGroup<T, S extends FieldValues>(props: IProps<T, S>) {
    const {
        control,
        name,
        label,
        data,
        placeholder,
        optionKey,
        secondOptionKey,
        rules,
        noOptionsText = 'Geen opties gevonden...',
        noMargin = false,
        onFetchSuggestions,
        onChangeCallback,
        popupIcon,
        autoFocus
    } = props;

    const [inputValue, setInputValue] = useState('');

    const debouncedFetchSuggestions = useMemo(() => onFetchSuggestions && debounce(onFetchSuggestions, 300), [onFetchSuggestions]);

    useEffect(() => {
        debouncedFetchSuggestions?.(inputValue);
    }, [inputValue]);

    const theme = useTheme();

    function getAdditionalLabel(option: any) {
        if (secondOptionKey !== undefined) {
            return option[secondOptionKey];
        } else {
            return '';
        }
    }

    function isOptionEqualToValue(option: any, value: any): boolean {
        // This is to remove the isOptionEqualToValue warning..
        if (!value.id || !value.problemDescription || value.problemDescription) {
            return true;
        }

        return option.id === value.id;
    }

    const renderInput: ControllerProps<S>['render'] = props => {
        const { field: { onChange, value, ...restProps }, fieldState: { error, invalid } } = props;

        function handleChange(_event: unknown, value: any) {
            onChange(value);

            onChangeCallback?.(value);
        }

        return (
            // TODO: find a way of checking noOptions..
            <StyledInputRoot noMargin={noMargin} noOptions={false}>
                <FormGroupLabel
                    rules={rules}
                    sx={{ color: theme.palette.text.primary, opacity: .5, mb: 1 }}
                >
                    {label}
                </FormGroupLabel>

                <Autocomplete<any>
                    {...restProps}
                    disablePortal
                    autoHighlight
                    getOptionLabel={option => `${option[optionKey] ? (option[optionKey]) : ''}${getAdditionalLabel(option) ? ` (${getAdditionalLabel(option)})` : ''}`}
                    isOptionEqualToValue={isOptionEqualToValue}
                    noOptionsText={noOptionsText}
                    onChange={handleChange}
                    options={data}
                    inputValue={inputValue}
                    popupIcon={popupIcon}
                    onInputChange={(_e, value) => setInputValue(value)}
                    PopperComponent={PopperComponent}
                    renderInput={(params) =>
                        <StyledTextfield
                            {...params}
                            error={invalid}
                            placeholder={placeholder}
                            autoFocus={autoFocus}
                        />
                    }
                    // This will fix the formstate validation and isOptionEqualToValue warning...
                    value={ObjectHelper.isEmpty(value) ? null : value ?? null}
                />

                <FormGroupErrorText error={invalid}>
                    {error?.message ?? ''}
                </FormGroupErrorText>
            </StyledInputRoot>
        );
    };

    return (
        <Controller
            render={renderInput}
            name={name}
            control={control}
            rules={rules}
        />
    );
}
