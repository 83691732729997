import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

// This hook was inspired by https://stackoverflow.com/a/57632587/1440557
export default function useInitializedEffect(effect: EffectCallback, deps?: DependencyList) {
    const hasInitialized = useRef(false);

    const internalEffect = hasInitialized.current
        ? effect
        : () => { };

    useEffect(internalEffect, deps);

    return function (initialized: boolean = true) {
        hasInitialized.current = initialized;
    };
}
