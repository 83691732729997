import { Button, Theme, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const sharedStyles = (theme: Theme): Parameters<ReturnType<typeof styled>>[1] => ({
    color: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 300,
    minWidth: 'unset',
    padding: 0,

    [theme.breakpoints.up('sm')]: {
        '&::before': {
            backgroundColor: theme.palette.text.primary,
            bottom: -4,
            content: '""',
            height: '2px',
            left: 0,
            position: 'absolute',
            textDecorationThickness: 2,
            textUnderlineOffset: 6,
            transform: 'scaleX(0)',
            transformOrigin: 'bottom left',
            transition: 'transform 0.3s ease-out',
            width: '100%',
        },

        '&:hover::before': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
            pointer: 'cursor',
        }
    }
});

export const ReadMoreButton = styled(Button)(({ theme }) => sharedStyles(theme));
export const ReadMoreLinkedButton = styled(Button<typeof Link>)(({ theme }) => sharedStyles(theme));