import { Box, styled, Typography } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(4),
    justifyContent: 'center',
}));

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <StyledBox>
                    <Typography variant='h3'>
                        Sorry er is iets misgegaan...
                    </Typography>
                </StyledBox>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;