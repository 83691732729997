import { Components, Theme } from '@mui/material';

export const overrideMuiDivider = (theme: Theme): Components['MuiDivider'] => {
    return {
        styleOverrides: {
            root: {
                borderColor: theme.palette.grey[500],
            },
        }
    };
};