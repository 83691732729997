import { Api } from '../ApiClients/clients';
import SnackbarManager from '../Components/Snackbar/SnackbarManager';

export default class UploadImageHelper {
    private static async uploadImage(fileName: string, imageToUpload: File) {
        try {
            return await Api.uploadImage({ fileName, data: imageToUpload });
        } catch (error) {
            SnackbarManager.showError(error);
        }
    }

    static getImageIdFromBlobUrl(url: string) {
        // The images are always of type .jpg , if this is not the case, check the back-end of the UploadService
        const regex = /\/([^/]+)\.jpg/;
        const match = url.match(regex);

        if (match) {
            return match[1];
        }
    }

    static async deleteCurrentImage(id: string) {
        try {
            await Api.deleteImage(id);
        } catch (error) {
            SnackbarManager.showError(error);
        }
    }

    static async uploadAndDeleteImage(id: string, imageToUpload: File, isNew: boolean) {
        if (!isNew) {
            await UploadImageHelper.deleteCurrentImage(id);
        }

        let imageUrl = await UploadImageHelper.uploadImage(id, imageToUpload);

        if (!imageUrl) {
            return id;
        }

        return `${imageUrl}?${Date.now()}`;;
    }
}