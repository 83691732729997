
import { useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useSkipSlidingIn(route: string) {
    const location = useLocation();

    const theme = useTheme();
    const delay = theme.transitions.duration.leavingScreen ?? 500;

    const [skipSlidingIn, setSkipSlidingIn] = useState(true);

    useEffect(() => {
        // Make sure it won't show the sliding in animation when you navigated directly to a child route
        const skipSlidingIn = location.pathname !== route;
        setSkipSlidingIn(skipSlidingIn);
    }, []);

    useEffect(() => {
        if (location.pathname === route) {
            setTimeout(() => {
                setSkipSlidingIn(false);
            }, delay);
        }
    }, [location.pathname]);

    return skipSlidingIn;
}
