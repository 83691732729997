
import { PropsWithChildren } from 'react';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom, useOnboardingState } from '../../Logic/onboardingState';

interface IProps { }

const StyledDiv = styled('div')(() => ({}));

export default function SwipeableOnboardingScreen(props: PropsWithChildren<IProps>) {
    const { children } = props;

    const { currentStep, totalSteps } = useAtomValue(onboardingStateAtom);
    const { navigateToNextStep, navigateToPreviousStep } = useOnboardingState();

    const handlers = useSwipeable({
        onSwipedLeft: () => lastOnboardingSwipe(),
        onSwipedRight: () => navigateToPreviousStep()
    });

    const navigate = useNavigate();

    function lastOnboardingSwipe() {
        navigateToNextStep();

        if (currentStep === totalSteps) {
            navigate('/login');
        }
    }

    return (
        <StyledDiv {...handlers}>
            {children}
        </StyledDiv>
    );
}
