import { Button, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { SlideLeftDialog, SlideUpDialog } from '../../../../Components/Dialogs/BaseDialogs';
import RoutingDialog from '../../../../Components/Dialogs/RoutingDialog';

function useSkipSlidingIn(route: string) {
    const location = useLocation();

    const theme = useTheme();
    const delay = theme.transitions.duration.leavingScreen ?? 500;

    const [skipSlidingIn, setSkipSlidingIn] = useState(false);

    useEffect(() => {
        // Make sure it won't show the sliding in animation when you navigated directly to the management items.
        setSkipSlidingIn(location.pathname !== route);
    }, []);

    useEffect(() => {
        if (location.pathname === route) {
            setTimeout(() => {
                setSkipSlidingIn(false);
            }, delay);
        }
    }, [location.pathname]);

    return skipSlidingIn;
}

function Users() {
    const navigate = useNavigate();

    return (
        <>
            <Button onClick={() => navigate('/routing-test/users/1')}>Klik</Button>

            <RoutingDialog
                DialogComponent={SlideUpDialog}
                backPath='/routing-test/users'
            >
                <Route path=':id' element={
                    <>
                        <div>Yes 2</div>
                        <Button onClick={() => navigate('/routing-test/users/1/edit')}>Edit</Button>
                    </>
                } />
                <Route path=':id/edit' element={<div>yes3</div>} />
            </RoutingDialog>
        </>
    );
};

export default function RoutingDialogTest() {
    const navigate = useNavigate();

    const skipSlidingIn = useSkipSlidingIn('/routing-test');

    return (
        <>
            <Button onClick={() => navigate('/routing-test/users')}>Klik</Button>

            <RoutingDialog
                skipSlidingIn={skipSlidingIn}
                DialogComponent={SlideLeftDialog}
                backPath='/routing-test'
            >
                <Route path='users/*' element={<Users />} />
            </RoutingDialog>
        </>
    );
}
