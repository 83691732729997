import { IUser } from '../Definitions/userDefinitions';
import config from '../Config';
import SnackbarManager from '../Components/Snackbar/SnackbarManager';

export default class SlackHelper {
    private static getSlackMessage(user: IUser, imageUrl: string) {
        const { firstName, fullName, buddyId, buddyFullName, teamName, startDate } = user;
        return {
            blocks: [
                {
                    type: 'header',
                    text: {
                        type: 'plain_text',
                        text: `${fullName} heeft het onboardingsproces afgerond!`
                    }
                },
                {
                    type: 'section',
                    text: {
                        type: 'mrkdwn',
                        text: `${firstName} voegt zich toe aan team ${teamName} ${buddyId ? `en heeft ${buddyFullName} als buddy` : ''}. En begint op ${new Date(startDate).toLocaleDateString()}, wens ${firstName} veel succes! :four_leaf_clover:`
                    }
                },
                {
                    type: 'image',
                    image_url: imageUrl,
                    alt_text: user.fullName
                }
            ]
        };

    }

    static async sendSlackNotification(user: IUser, imageUrl: string): Promise<void> {
        const webHookURL = config.slackWebhookURL;

        if (webHookURL) {
            try {
                await fetch(webHookURL, {
                    method: 'POST',
                    body: JSON.stringify(SlackHelper.getSlackMessage(user, imageUrl))
                });
            } catch (error) {
                SnackbarManager.showError(error);
            }
        };
    }
}