import dayjs, { Dayjs } from 'dayjs';
import { range } from 'lodash';

export type DateRange<T = Dayjs> = {
    start: T;
    end: T;
}

export default class DateHelper {
    static getDayOfTheWeek(dayofWeekInput: number): string {
        return dayjs().isoWeekday(dayofWeekInput).format('dddd');
    }

    static getMonthLabel(monthIndex: number | string) {
        return dayjs().month(Number(monthIndex)).format('MMMM');
    }

    static getMonthLabelShort(monthIndex: number | string) {
        return dayjs().month(Number(monthIndex)).format('MMM');
    }

    static getDaysUntilVacation(vacationDate: string): number {
        return Math.ceil(dayjs(vacationDate).diff(new Date(), 'day', true));
    }

    static isWeekendDay(day: Dayjs): boolean {
        return (day.day() === 0 || day.day() === 6);
    }

    static skipWeekend(day: Dayjs): Dayjs {
        return day.clone().add(1, 'week').startOf('isoWeek');
    }

    // Set the time to twelve o'clock so that the date is stored correctly in the database.
    static getDateTimeToNoon(date: string): string {
        const originalDate = new Date(date);
        originalDate.setHours(12);
        return originalDate.toISOString();
    }
}

export enum DateFormats {
    DayWithMonth = 'DD-MM',
    YearMonthDay = 'YYYY-MM-DD',
    DayMonthYear = 'DD-MM-YYYY',
    DayFullMonthYear = 'DD MMMM YYYY',
    DayNameDayMonthName = 'dddd D MMMM',
    DayFullMonthYearTime = 'D MMMM YYYY HH:mm'
}

// TODO: Check if we just could use the ISO-strings
// ex. Mon, 19 Dec 2022 23:00:00 GMT -> returns 2022-12-20T00:00:00
export function getLocalDate(date: string) {
    return dayjs(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
}

export function getNumberOfDaysInMonth(year: number, month: number) {
    return dayjs(new Date(year, month)).daysInMonth();
}

export function formatDate(date: Date) {
    return date.toLocaleDateString('nl-NL');
}

export function getAllDaysInMonth(year: number, month: number): Dayjs[] {
    const daysInMonthList = range(1, getNumberOfDaysInMonth(year, month) + 1);
    return daysInMonthList.map(day => dayjs(`${year}-${month + 1}-${day}`));
}

export function isTodaySameAsInput(date: string) {
    const inputDate = dayjs(date).format(DateFormats.DayWithMonth);
    const today = dayjs().format(DateFormats.DayWithMonth);

    return inputDate === today;
}

export function isWinterTime() {
    const currentYear = dayjs().year();
    return dayjs().isAfter(`${currentYear}-12-01`);
}
