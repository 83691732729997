import { DialogProps, Slide, SlideProps, dialogClasses, styled } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import DialogBase from './DialogBase';

interface IProps extends DialogProps {
    skipSlidingIn?: boolean;
    dialogProps?: Partial<{ offsetTop: number }>
}

const StyledDialogBase = styled(DialogBase)(() => ({
    [`& .${dialogClasses.container}`]: {
        height: 'unset',
    },

    [`& .${dialogClasses.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: ['100vh', '100dvh'],
    }
}));

// The transition should be defined outside of the component, otherwise the backdrop will remain mounted.
const SlideUpTransition = forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide direction='up' ref={ref} {...props as PropsWithChildren<TransitionProps> & Pick<SlideProps, 'children'>} />;
});

export default function SlideUpDialogComponent(props: IProps) {
    const { skipSlidingIn, dialogProps, ...restProps } = props;

    return (
        <StyledDialogBase
            {...restProps}
            TransitionComponent={!skipSlidingIn ? SlideUpTransition : undefined}
            transitionDuration={skipSlidingIn ? 0 : undefined}
            sx={{ top: dialogProps?.offsetTop }}
        />
    );
}
