import { Api } from '../../../../../ApiClients/clients';
import { Box, ButtonProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SlackHelper } from '../../../../../Helpers';
import EastIcon from '@mui/icons-material/East';
import SnackbarManager from '../../../../../Components/Snackbar/SnackbarManager';
import UsersLogic from '../../../../../Logic/UsersLogic';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom, useOnboardingState } from '../../../Logic/onboardingState';
import OnboardingLogic from '../../../Logic/onboardingLogic';

export default function OnboardingNavigateNextButton(props: ButtonProps) {
    const { type, ...restProps } = props;

    const { user, currentStep, totalSteps, uploadedPhoto } = useAtomValue(onboardingStateAtom);
    const { navigateToNextStep } = useOnboardingState();

    const onClick = type === 'submit'
        ? undefined
        : handleClick;

    async function handleClick() {
        try {
            if (currentStep === totalSteps && user.onboardingLink) {

                if (uploadedPhoto) {
                    const imageUrl = await OnboardingLogic.uploadOnboardingImage(user.id, user.onboardingLink, uploadedPhoto);

                    if (imageUrl) {
                        await SlackHelper.sendSlackNotification(user, imageUrl);
                        user.profilePicture = imageUrl;
                    }
                }

                await Api.updateUserByOnboardinglink(user.onboardingLink, UsersLogic.mapUserToUpdateUser({ ...user, onboardingLink: undefined }));
            }

            navigateToNextStep();
        } catch (error) {
            SnackbarManager.showError(error);
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <LoadingButton
                {...restProps}
                sx={{ mt: 1 }}
                onClick={onClick}
                size='large'
                type={type}
                variant='contained'
            >
                <EastIcon />
            </LoadingButton>
        </Box>
    );
}
