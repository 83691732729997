import { IEditablePersonalLearningPath } from '../../Modules/Main/Components/LearningPath/Logic/LearningPathsStoreLogic';
import { ILearningPath, ILearningPathStep, LearningPathType } from '../../Modules/Main/Components/LearningPath/Definitions/learningPathDefinitions';
import { IUser } from '../../Definitions/userDefinitions';
import { LearningPathTemplateSummary, PersonalLearningPath, PersonalLearningPathSummary, Specialty } from '../../ApiClients/ApiClient';
import { PropsWithChildren, useState } from 'react';
import { Provider, atom, createStore } from 'jotai';
import { UsersLogic } from '../../Logic';
import IndividualLearningPathLogic from '../../Modules/Main/Components/LearningPath/Individual/Logic/IndividualLearningPathLogic';
import TemplateLogic from '../../Modules/Main/Modules/Management/Scenes/ManagementLearningPathOverview/Template/Logic/TemplateLogic';

// This is for every atom that is used in Templates and Personal (PT)
const learningPath = {
    learningPathType: atom<LearningPathType | undefined>(undefined),
    isSaving: atom(false),
    isLoading: atom(true),
    isDirty: atom(false),
    currentStep: atom(0),
    selected: {
        learningPath: atom<ILearningPath>(TemplateLogic.getEmptyTemplate()),
        learningPathStep: atom<ILearningPathStep>(TemplateLogic.getEmptyTemplateStep()),
    }
};

// This is for every atom that is used in Template (T)
const template = {
    templateSummaries: atom<LearningPathTemplateSummary[]>([]),
    specialties: atom<Specialty[]>([]),
};

// This is for every atom that is used in Personal (P)
const individual = {
    isLoading: atom(true),
    learningPaths: atom<PersonalLearningPathSummary[]>([]),
    allUsers: atom<IUser[]>([]),
    selectedUser: atom<IUser>(UsersLogic.getEmptyUser()),
    selectedUsers: atom<IUser[]>([]),
    archivedLearningPaths: atom<PersonalLearningPathSummary[]>([]),
    selected: {
        learningPath: atom<PersonalLearningPath>(IndividualLearningPathLogic.getEmptyIndividualLearningPath()),
        editableLearningPath: atom<IEditablePersonalLearningPath>(IndividualLearningPathLogic.getEmptyEditableIndividualLearningPath()),
        learningPathStep: atom<ILearningPathStep>(IndividualLearningPathLogic.getEmptyLearningPathStep()),
    }
};

export const learningPathsStoreAtoms = {
    learningPath,
    template,
    individual,
};

export default function LearningPathsStoreProvider(props: PropsWithChildren) {
    const [store] = useState(createStore);

    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    );
}
