import { Components, Theme, accordionDetailsClasses, accordionSummaryClasses } from '@mui/material';

export const overrideMuiAccordion = (theme: Theme): Components['MuiAccordion'] => {
    return {
        styleOverrides: {
            root: {
                marginTop: theme.spacing(.75),
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[600],
                padding: theme.spacing(1, 2),

                [`&.${accordionSummaryClasses.expanded}`]: {
                    border: `1px solid ${theme.palette.grey[500]}`
                },

                [`& .${accordionSummaryClasses.root}`]: {
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.grey[600],
                    padding: theme.spacing(1, 2),

                    [`&.${accordionSummaryClasses.expanded}`]: {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0
                    },
                },

                [`& .${accordionDetailsClasses.root}`]: {
                    backgroundColor: theme.palette.grey[600],
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius
                }
            },
        }
    };
};