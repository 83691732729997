import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import config from '../Config';
import { ApiClient, AuthenticateResponse, RefreshAuthentication } from './ApiClient';
import { IAuthToken, IRefreshToken } from '../Modules/Login/Definitions/LoginDefinitions';
import jwtDecode from 'jwt-decode';

const instance: AxiosInstance = axios.create({
    transformResponse: (data: any) => data,
});

const authUrl = '/api/auth';
const refreshAuthApiUrl = config.apiUrl + '/api/auth/refresh';

instance.interceptors.request.use(async (axiosConfig: InternalAxiosRequestConfig) => {
    const requestUrl = axiosConfig.url;

    // The auth endpoints are used to login or refresh sessions
    // So this one shouldn't be intercepted.
    if (!requestUrl || requestUrl.includes(authUrl)) {
        return axiosConfig;
    }

    const authToken = localStorage.getItem('authToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!authToken || !refreshToken) {
        localStorage.clear();
        window.location.href = '/login';

        return axiosConfig;
    }

    axiosConfig.headers.set('Authorization', `Bearer ${authToken}`);

    if (isTokenExpired(authToken)) {
        if (isTokenExpired(refreshToken)) {
            localStorage.clear();
            window.location.href = '/login';

            return axiosConfig;
        }

        const refreshResponse = await refreshSession(authToken, refreshToken);
        axiosConfig.headers.set('Authorization', `Bearer ${refreshResponse.authToken}`);

        localStorage.setItem('authToken', refreshResponse.authToken);
        localStorage.setItem('refreshToken', refreshResponse.refreshToken);
    }

    return axiosConfig;
});

export const Api = new ApiClient(config.apiUrl, instance);

function isTokenExpired(token: string) {
    const tokenPayload: IRefreshToken | IAuthToken = jwtDecode(token);
    return new Date(tokenPayload.exp * 1000) < new Date();
}

async function refreshSession(authToken: string, refreshToken: string): Promise<AuthenticateResponse> {
    const tokens: RefreshAuthentication = { authToken, refreshToken };
    const data = JSON.stringify(tokens);

    const axiosConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    const response = await instance.post(refreshAuthApiUrl, data, axiosConfig);
    return JSON.parse(response.data);
}
