import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IProps extends LoadingButtonProps {
    tooltipText: string;
}

export default function TooltipButton(props: PropsWithChildren<IProps>) {
    const { children, disabled, tooltipText, ...restProps } = props;

    return (
        <Tooltip title={disabled && tooltipText} arrow>
            <div>
                <LoadingButton disabled={disabled} {...restProps}>
                    {children}
                </LoadingButton>
            </div>
        </Tooltip>
    );
}
