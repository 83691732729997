import { Cancel as CancelIcon } from '@mui/icons-material';
import { ButtonBase, DialogProps, Slide, SlideProps, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { PropsWithChildren, SyntheticEvent, forwardRef } from 'react';
import { Content } from '../../Content';
import DialogBase from './DialogBase';

interface IProps extends DialogProps {
    skipSlidingIn?: boolean;
    dialogProps?: Partial<{ offsetTop: number; }>;
}

// The transition should be defined outside of the component, otherwise the backdrop will remain mounted.
const SlideUpTransition = forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide direction='up' ref={ref} {...props as PropsWithChildren<TransitionProps> & Pick<SlideProps, 'children'>} />;
});

const HeaderContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(3, 0),
}));

const IconButton = styled(ButtonBase)({
    zIndex: 2,
    fontSize: 42,
    borderRadius: 9999,
    position: 'relative',
});

export default function SlideUpDialog(props: IProps) {
    const { skipSlidingIn, dialogProps, onClose, ...restProps } = props;

    function handleClose(event: SyntheticEvent<HTMLButtonElement>) {
        onClose?.(event, 'backdropClick');
    }

    return (
        <DialogBase
            {...restProps}
            TransitionComponent={SlideUpTransition}
            transitionDuration={skipSlidingIn ? 0 : undefined}
            sx={{ top: dialogProps?.offsetTop }}
            onClose={onClose}
        >
            <Content>
                <HeaderContainer>
                    <IconButton onClick={handleClose}>
                        <CancelIcon />
                    </IconButton>
                </HeaderContainer>

                {props.children}
            </Content>
        </DialogBase>
    );
}
