import { MainContext } from '../../Modules/Main/MainContext';
import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import LoadingIndicator from '../../Components/Content/LoadingIndicator/LoadingIndicator';
import UsersLogic from '../../Logic/UsersLogic';
import { useFetchData } from '../../Hooks';
import SnackbarManager from '../../Components/Snackbar/SnackbarManager';

export default function AuthenticatedUserHandler() {
    const { loggedInUser, updateMainState } = useContext(MainContext);

    const [isLoading] = useFetchData(initialize, []);

    async function initialize() {
        try{
            const loggedInUser = await UsersLogic.fetchCurrentlyLoggedInUser();
            updateMainState({ loggedInUser });
        } catch(error){
            SnackbarManager.showError(error);
            localStorage.clear();
        }
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return loggedInUser ? <Outlet /> : <Navigate to='/login' />;
}