import { calendarPickerClasses } from '@mui/lab';
import { Components, dialogActionsClasses, iconButtonClasses, svgIconClasses, Theme, typographyClasses } from '@mui/material';
import {
    datePickerToolbarClasses,
    dayPickerClasses,
    pickersCalendarHeaderClasses,
    pickersDayClasses,
    pickersLayoutClasses,
    pickersMonthClasses,
    pickersYearClasses
} from '@mui/x-date-pickers';
import { pickersArrowSwitcherClasses } from '@mui/x-date-pickers/internals';

export const overrideMuiPaper = (theme: Theme): Components['MuiPaper'] => {
    return {
        styleOverrides: {
            outlined: {
                borderColor: '#373737',
                borderRadius: 8,
            },
            root: {
                backgroundColor: theme.palette.background.default,

                '& .Mui-selected': {
                    background: theme.palette.primary.main,
                },

                [` .${pickersYearClasses.root}, .${pickersMonthClasses.root}`]: {
                    justifyContent: 'center',
                    padding: theme.spacing(.5, 0, 1),
                    color: 'purple',

                    ' .Mui-disabled': {
                        color: theme.palette.grey[400],
                    }
                },

                [` .${pickersYearClasses.yearButton},  .${pickersMonthClasses.root}`]: {
                    margin: theme.spacing(1),
                    maxWidth: 52,
                    minHeight: 52,
                    borderRadius: theme.shape.borderRadius,
                    color: theme.palette.text.primary,

                    ' .Mui-disabled': {
                        color: theme.palette.grey[400],
                    }
                },

                [` .${calendarPickerClasses}`]: {
                    background: theme.palette.grey[600],

                    [` .${pickersCalendarHeaderClasses.label}`]: {
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.fontWeightMedium,
                    },

                    [` .${svgIconClasses.root}`]: {
                        color: theme.palette.text.primary,
                    },

                    [` .${pickersYearClasses.yearButton}`]: {
                        color: theme.palette.text.primary,

                        '&:disabled': {
                            opacity: .2,
                        },

                        '&:hover': {
                            background: theme.palette.grey[500],
                        },
                    },

                    [` .${pickersYearClasses.root}`]: {
                        '.Mui-selected': {
                            background: theme.palette.primary.main,
                            color: theme.palette.grey[900],

                            '&:hover': {
                                background: theme.palette.primary.main,
                                color: theme.palette.text.primary,
                            }
                        },
                    },

                    [` .${pickersMonthClasses.root}`]: {
                        color: theme.palette.text.primary,

                        '&.Mui-selected': {
                            background: theme.palette.primary.main,
                            color: theme.palette.grey[900],

                            '&:hover': {
                                background: theme.palette.primary.main,
                                color: theme.palette.text.primary,
                            }
                        },

                        '&:disabled': {
                            opacity: .2,
                        },

                        '&:hover': {
                            background: theme.palette.grey[500],
                        },
                    },

                    [` .${pickersDayClasses.root}`]: {
                        background: theme.palette.grey[600],

                        [` .${pickersDayClasses.today}`]: {
                            border: '0px solid transparent',
                        },

                        [` .${pickersDayClasses.dayOutsideMonth}`]: {
                            fontWeight: 300,
                        },

                        '&[aria-selected=true]': {
                            background: theme.palette.primary.main,
                            color: theme.palette.grey[900],

                            '&:hover': {
                                background: theme.palette.primary.main,
                                color: theme.palette.grey[900],
                            }
                        },

                        '&:disabled': {
                            color: theme.palette.text.primary,
                            opacity: .2,
                        },

                        '&:focus': {
                            background: theme.palette.primary.main,
                        },

                        '&:hover': {
                            background: theme.palette.grey[500],
                        },

                    },

                    [` .${pickersDayClasses.dayOutsideMonth}`]: {
                        color: theme.palette.text.primary,
                        opacity: .5,
                    },

                    [` .${dayPickerClasses.weekDayLabel}`]: {
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.fontWeightMedium,
                    },
                },

                [` .${pickersLayoutClasses.root}`]: {

                    [` .${pickersDayClasses.today}`]: {
                        ':not(.Mui-selected)': {
                            border: '1px solid white'
                        }
                    },

                    [` .${pickersDayClasses.dayOutsideMonth}`]: {
                        color: theme.palette.grey[400],
                    },

                    [` .${datePickerToolbarClasses.root}`]: {
                        background: theme.palette.grey[600],
                        borderBottom: `1px solid ${theme.palette.primary.main}`,

                        [` .${typographyClasses.overline}`]: {
                            color: theme.palette.text.primary,
                        }
                    },

                    [` .${pickersCalendarHeaderClasses.root}`]: {
                        borderBottom: `1px solid ${theme.palette.grey[500]}`,
                        paddingTop: theme.spacing(3),
                        paddingBottom: theme.spacing(3),
                        marginTop: theme.spacing(1),
                        marginBottom: 0,
                        color: 'white',

                        [` .${pickersCalendarHeaderClasses.label}`]: {
                            color: theme.palette.text.primary,
                        },

                        [` .${pickersCalendarHeaderClasses.switchViewButton}`]: {
                            color: theme.palette.text.primary,

                        },

                        [` .${pickersArrowSwitcherClasses.button}`]: {
                            color: theme.palette.text.primary,

                        },

                        [` .${iconButtonClasses.root}`]: {
                            '&:disabled': {
                                [` .${svgIconClasses.root}`]: {
                                    opacity: .2
                                },
                            },
                        },
                    },

                    [` .${pickersLayoutClasses.contentWrapper}`]: {
                        background: theme.palette.grey[600],

                        ' .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel': {
                            color: theme.palette.text.primary,
                        },

                        ' .MuiDayCalendar-weekContainer .Mui-disabled': {
                            color: theme.palette.grey[500],
                        },
                    }
                },

                [` .${dialogActionsClasses.spacing}`]: {
                    background: theme.palette.grey[600],
                    borderTop: `1px solid ${theme.palette.primary.main}`,
                }
            }
        }
    };
};