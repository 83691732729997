import { alertClasses, Components, svgIconClasses, Theme } from '@mui/material';

export const overrideMuiAlert = (theme: Theme): Components['MuiAlert'] => {
    return {
        styleOverrides: {
            root: {
                [` .${alertClasses.icon}`]: {
                    color: theme.palette.background.default,
                }
            },
            standardInfo: {
                backgroundColor: theme.palette.primary.main,
                textColor: 'white'
            },
            standardWarning: {
                backgroundColor: theme.palette.warning.main,
            },
            standardSuccess: {
                backgroundColor: theme.palette.success.main,
                [` .${alertClasses.icon}`]: {
                    color: 'white'
                },
                [` .${alertClasses.message}`]: {
                    color: theme.palette.text.primary,
                },
                [` .${svgIconClasses.root}`]: {
                    color: theme.palette.text.primary,
                }
            },
            standardError: {
                backgroundColor: theme.palette.error.main,
                [` .${alertClasses.icon}`]: {
                    color: 'white'
                },
                [` .${alertClasses.message}`]: {
                    color: theme.palette.text.primary,
                },
                [` .${svgIconClasses.root}`]: {
                    color: theme.palette.text.primary,
                }
            }
        }
    };
}; 