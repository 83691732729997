import { Box, SxProps } from '@mui/material';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateValidationError } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { FormGroupErrorText, FormGroupLabel } from '.';
import { IDatePickerFormGroupProps } from './Definitions/formGroupDefinitions';
import dayjs, { Dayjs } from 'dayjs';

interface IProps<T extends FieldValues> extends IDatePickerFormGroupProps<T> {
    sx?: SxProps;
    onChangeCallback?: () => void;
}

export default function DateFormGroup<T extends FieldValues>(props: IProps<T>) {
    const {
        control,
        disabled,
        disablePast = false,
        disableFuture = false,
        fullWidth,
        label,
        name,
        onChangeCallback,
        placeholder = 'Selecteer een datum',
        rules,
        sx,
    } = props;

    const renderInput: ControllerProps<T>['render'] = props => {
        const { field: { onChange, value }, fieldState: { error, invalid } } = props;

        function handleChange(value: Dayjs | null, context: FieldChangeHandlerContext<DateValidationError>) {
            onChange(value, context);
            onChangeCallback?.();
        }

        return (
            <Box sx={sx}>
                <FormGroupLabel rules={rules}>
                    {label}
                </FormGroupLabel>

                <DatePicker
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    onChange={handleChange}
                    value={value ? dayjs(value) : null}
                    format='L'
                    disabled={disabled}
                    showDaysOutsideCurrentMonth
                    slotProps={{
                        textField: {
                            error: invalid,
                            fullWidth: fullWidth,
                            placeholder: placeholder,
                            inputProps: {
                                placeholder: placeholder
                            }
                        }
                    }}
                />

                <FormGroupErrorText error={invalid}>
                    {error?.message ?? ''}
                </FormGroupErrorText>
            </Box>
        );
    };

    return (
        <Controller
            control={control}
            name={name}
            render={renderInput}
            rules={rules}
        />
    );
}
