import { ButtonProps, styled, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom, useOnboardingState } from '../../../Logic/onboardingState';

interface IProps extends ButtonProps { }

const StyledButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    minWidth: theme.spacing(6),
    minHeight: theme.spacing(6),
    borderRadius: '50%',

    '&:hover': {
        background: 'none',
    }
}));

export default function OnboardingNavigatePreviousButton(props: IProps) {
    const { currentStep } = useAtomValue(onboardingStateAtom);
    const { navigateToPreviousStep } = useOnboardingState();

    if (currentStep === 1) {
        return null;
    }

    return (
        <StyledButton
            onClick={() => navigateToPreviousStep()}
            type={props.type}
        >
            <ArrowBackIcon />
        </StyledButton>
    );
}