import { Stack, StackProps } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IProps extends StackProps { }

export default function StackComponent(props: PropsWithChildren<IProps>) {
    const { children } = props;

    return (
        <Stack {...props} direction='row' spacing={2}>
            {children}
        </Stack>
    );
}
