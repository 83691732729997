import { Components, Theme, tableBodyClasses, tableCellClasses, tablePaginationClasses, tableRowClasses, typographyClasses } from '@mui/material';

export const overrideMuiTableContainer = (theme: Theme): Components['MuiTableContainer'] => {
    return {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.grey[600],
                borderRadius: theme.shape.borderRadius * 2,

                [`& .${tableBodyClasses.root}`]: {
                    [`& .${tableRowClasses.root}`]: {
                        color: '#fff',
                        cursor: 'pointer',
                        '&:hover': { 
                            backgroundColor: theme.palette.grey[700] 
                        },
                    }
                },

                [`& .${tableCellClasses.root}`]: {
                    borderColor: theme.palette.grey[800],                
                    color: 'grey.100',

                    [`& .${typographyClasses.root}`]: {
                        color: 'grey.100'
                    }
                },

                [`& .${tablePaginationClasses.root}`]: {
                    backgroundColor: theme.palette.grey[700],
                    color: '#fff',
                    borderRadius: '0px 0px 8px 8px'
                },

                [`& .${tablePaginationClasses.select}`]: {
                    color: theme.palette.grey[400]
                },
                  
                [`& .${tablePaginationClasses.selectIcon}`]: {
                    color: theme.palette.grey[400]
                },

                [`& .${tablePaginationClasses.menuItem}`]: {
                    backgroundColor: theme.palette.grey[600],
                    ':hover': {
                        backgroundColor: theme.palette.grey[700]
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.dark + '!important',
                        color: theme.palette.text.secondary
                    },
                },
            },

        }
    };
};

export const overrideMuiTablePagination = (theme: Theme): Components['MuiTablePagination'] => {
    return {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.grey[700],
                color: '#fff',
                borderRadius: '0px 0px 8px 8px'
            },
            select: {
                color: theme.palette.grey[400]
            },
            selectIcon: {
                color: theme.palette.grey[400]
            },
            menuItem: {
                backgroundColor: theme.palette.grey[600],
                ':hover': {
                    backgroundColor: theme.palette.grey[700]
                },
                '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.dark + '!important',
                    color: theme.palette.text.secondary
                },
            }
        }
    };
};

