import { useEffect, useState } from 'react';
import SnackbarManager from '../Components/Snackbar/SnackbarManager';

export default function useFetchData(callback: () => Promise<void>, dependencies: any[]) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    async function fetchData() {
        setIsLoading(true);

        try {
            await callback();
        } catch (error) {
            setError(error);
            SnackbarManager.showError(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, dependencies);

    return [isLoading, error];
}