import { styled, SvgIcon, SvgIconProps } from '@mui/material';

const Root = styled(SvgIcon)({
    color: '#fff'
});

export default function Logo(props: SvgIconProps) {
    return (
        <Root {...props} viewBox='0 0 1233.277 325.886'>
            <path d='M97.069,96.739V233.916c0,13.737-2.331,26.23-7.009,37.478-4.661,11.267-11.249,20.868-19.764,28.84-8.516,7.955-18.468,14.193-29.857,18.73-11.389,4.521-23.952,6.799-37.689,6.799-.928,0-1.839-.018-2.751-.035v-56.84c6.938-.245,13.089-2.032,18.415-5.309,5.765-3.574,10.285-8.515,13.579-14.841,3.294-6.308,4.941-13.439,4.941-21.411V96.739h60.134Z' />
            <path d='M324.11,127.772c-9.305-9.854-20.273-17.492-32.904-22.912-12.641-5.41-26.451-8.116-41.446-8.116-16.393,0-31.449,2.847-45.198,8.532-13.744,5.695-25.617,13.688-35.614,23.949-9.997,10.281-17.776,22.566-23.324,36.865-5.563,14.308-8.334,30.072-8.334,47.278,0,21.661,4.927,40.973,14.787,57.905,9.854,16.942,23.746,30.264,41.654,39.986,17.914,9.722,38.807,14.583,62.69,14.583,10.271,0,20.548-1.251,30.824-3.752,10.271-2.502,20.268-6.244,29.995-11.247,9.717-4.994,18.463-10.963,26.242-17.909l-27.91-39.569h0c-9.442,7.495-18.193,12.772-26.242,15.823-8.059,3.061-16.942,4.587-26.659,4.587-14.161,0-26.451-2.644-36.864-7.922-10.414-5.268-18.397-12.834-23.949-22.698-3.186-5.648-5.426-11.839-6.785-18.539h157.156l.417-18.326c.275-15.823-2.085-30.406-7.083-43.739-4.998-13.322-12.153-24.915-21.453-34.78Zm-27.697,60.194h-100.733c1.052-4.616,2.469-8.866,4.301-12.702,4.302-9.02,10.688-15.965,19.159-20.827,8.471-4.861,18.956-7.292,31.449-7.292,8.054,0,15.412,1.739,22.078,5.207,6.666,3.478,12.147,8.116,16.454,13.952,4.302,5.828,6.727,12.642,7.291,20.411v1.251Z' />
            <rect x='897.957' y='96.744' width='59.151' height='229.015' />
            <path d='M675.864,192.127c0-14.981-2.926-26.125-8.971-33.309-5.922-7.288-14.981-10.846-27.282-10.846-6.342,0-12.282,1.051-17.907,3.136-5.519,2.085-10.198,4.994-14.158,8.953-3.855,3.855-6.885,8.323-9.164,13.335-2.19,4.993-3.329,10.513-3.329,16.663v135.705h-59.153V191.303c0-13.964-3.136-24.582-9.374-32.082-6.255-7.499-15.208-11.249-26.878-11.249-6.027,0-11.862,1.051-17.486,3.136-5.519,2.085-10.197,4.994-14.158,8.744-3.854,3.749-6.885,8.129-9.164,13.334-2.19,5.099-3.346,10.723-3.346,16.873v135.705h-59.556V96.74h57.067l.824,33.098,3.539-4.135c5.221-6.044,11.038-11.038,17.714-15.209,6.659-4.17,13.842-7.499,21.447-10.005,7.709-2.488,15.209-3.749,22.708-3.749,11.652,0,22.077,1.769,31.031,5.414,9.059,3.539,16.768,9.269,23.111,17.084,4.38,5.203,8.025,11.564,10.829,19.063,3.242-5.116,7.201-10.005,11.669-14.472,5.624-5.624,11.88-10.426,18.748-14.385,6.974-4.048,14.158-7.184,21.656-9.374,7.5-2.278,14.893-3.329,22.077-3.329,17.294,0,31.452,3.434,42.7,10.425,11.249,6.869,19.782,17.066,25.406,30.611,5.73,13.439,8.533,30.207,8.533,50.199l-.035,37.114h-59.1v-32.961Z' />
            <rect x='675.858' y='266.613' width='177.443' height='59.146' fill='#aadb1e' />
            <path d='M1174.125,0V124.593c-3.328-3.502-7.058-6.795-11.244-9.895-7.499-5.554-16.032-9.935-25.617-13.124-9.584-3.189-19.519-4.783-29.787-4.783-19.449,0-36.795,4.994-52.074,14.981-15.261,10.005-27.281,23.602-36.024,40.825-8.743,17.224-13.124,36.935-13.124,59.153,0,21.937,4.38,41.579,13.124,58.925,8.743,17.364,20.833,30.908,36.235,40.633,15.419,9.707,32.695,14.578,51.864,14.578,10.268,0,20.062-1.472,29.366-4.38,9.304-2.909,17.627-6.938,24.986-12.072,4.711-3.293,8.81-6.83,12.295-10.631v26.808h59.151V0h-59.151Zm-16.833,245.479c-4.731,9.724-11.179,17.364-19.361,22.918-8.2,5.554-17.995,8.323-29.366,8.323-10.846,0-20.413-2.768-28.753-8.323-8.323-5.554-14.788-13.194-19.361-22.918-4.591-9.707-6.869-20.956-6.869-33.729,0-13.054,2.278-24.513,6.869-34.377,4.573-9.847,11.038-17.486,19.361-22.901,8.34-5.414,17.907-8.13,28.753-8.13,11.371,0,21.166,2.716,29.366,8.13,8.182,5.414,14.63,13.053,19.361,22.901,4.713,9.865,7.079,21.324,7.079,34.377,0,12.773-2.365,24.022-7.079,33.729Z' />
            <path d='M67.258,.149h0C47.777,.149,31.985,15.942,31.985,35.422s15.792,35.273,35.273,35.273h0c19.481,0,35.273-15.793,35.273-35.273S86.738,.149,67.258,.149Z' />
            <ellipse cx='927.532' cy='35.422' rx='35.329' ry='35.203' transform='translate(247.596 667.291) rotate(-45.089)' />
        </Root>
    );
}