import { styled, Typography } from '@mui/material';
import { Content } from '../Content';
import EmptyStateIndicator from '../Content/LoadingIndicator/EmptyStateIndicator';

interface IProps {
    title: string;
}

const StyledDiv = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
}));

export default function NotFoundComponent(props: IProps) {
    return (
        <Content>
            <StyledDiv>
                <EmptyStateIndicator />
                <Typography variant='h3'>{props.title}</Typography>
            </StyledDiv>
        </Content>
    );
}
