import { ArrowBack } from '@mui/icons-material';
import { IconButton, styled, SxProps, Typography } from '@mui/material';
import { Content } from '../Content';
import { ISearchFieldProps } from './ColoredDialogSearchInput';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import theme from '../../Theme';

interface IProps {
    title: string;
    subTitle?: string | JSX.Element;
    backButtonPathname: string;
    searchFieldProps?: ISearchFieldProps;
    sx?: SxProps;
    handleCalenderIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.grey[700],
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: 21,
}));

const HeadingContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    // [theme.breakpoints.down('md')]: {
    //     flexDirection: 'column',
    //     alignItems: 'unset',
    // }
}));

const NavigationHeading = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

export default function ColoredDialogHeader(props: PropsWithChildren<IProps>) {
    const { title, subTitle, backButtonPathname, children, sx, handleCalenderIconClick } = props;

    const navigate = useNavigate();

    function handleNavigate() {
        navigate(backButtonPathname);
    }

    return (
        <Root sx={sx}>
            <Content>
                <HeadingContainer>
                    <NavigationHeading>
                        <StyledIconButton onClick={handleNavigate} >
                            <ArrowBack />
                        </StyledIconButton>

                        <Typography variant='h2' color={theme.palette.text.primary}>{title}</Typography>
                    </NavigationHeading>

                    {handleCalenderIconClick &&
                        <StyledIconButton onClick={handleCalenderIconClick}>
                            <CalendarMonthIcon />
                        </StyledIconButton>
                    }

                    {subTitle}
                </HeadingContainer>

                {children}
            </Content>
        </Root>
    );
}
