import { PaletteOptions } from '@mui/material/styles';

const palette: PaletteOptions = {
    background: {
        default: '#111111'
    },
    text: {
        primary: '#FFFFFF',
        secondary: '#111111',
    },
    primary: {
        main: '#AADE1A',
        dark: '#9dcd18',
    },
    secondary: {
        main: '#FFFFFF'
    },
    warning: {
        main: '#F25C05'
    },
    info: {
        main: '#0476D9',
        light: '#F205E2'
    },
    grey: {
        200: '#888888',
        300: '#98979C',
        400: '#717171',
        500: '#373737',
        600: '#232323',
        700: '#1A1A1A',
        800: '#111111',
        900: '#000000',
    },
};

export default palette;