import { InputLabel, InputLabelProps, Tooltip, Typography, styled, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { FieldValues, Path, RegisterOptions } from 'react-hook-form';

const StyledInputLabel = styled(InputLabel, { shouldForwardProp: p => p !== 'isLight' })<{ isLight?: boolean }>(({ theme, isLight }) => ({
    color: isLight ? theme.palette.text.secondary : theme.palette.text.primary,
    opacity: isLight ? 1 : .5,
    whiteSpace: 'unset',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(.5),
    margin: theme.spacing(0, 1, 1, 0)
}));

interface IProps<T extends FieldValues> extends InputLabelProps {
    isLight?: boolean;
    tooltipText?: string;
    rules?: Omit<RegisterOptions<T, Path<T>>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> | undefined
}

export default function FormGroupLabel<T extends FieldValues>(props: IProps<T>) {
    const { children, isLight, rules, tooltipText, ...restProps } = props;

    const theme = useTheme();

    const isOptional = !(rules && rules.required);

    if (!children) {
        return null;
    }

    return (
        <StyledInputLabel {...restProps} isLight={isLight}>
            {children}

            {isOptional &&
                <Typography sx={{ color: isLight ? theme.palette.grey[800] : 'inherit', opacity: .6 }}>
                    (optioneel)
                </Typography>}

            {tooltipText &&
                <Tooltip
                    title={tooltipText}
                    placement='top'
                    arrow
                >
                    <InfoIcon
                        fontSize='small'
                        sx={{
                            ml: .5,
                            mt: .3,
                            cursor: 'help'
                        }}
                    />
                </Tooltip>
            }
        </StyledInputLabel>
    );
}
