import { Box, Button, SxProps, styled } from '@mui/material';
import React, { useRef, useState } from 'react';
import AddUploadImageEmptyState from '../../EmptyStates/AddUploadImageEmptyState';
import SnackbarManager from '../../Snackbar/SnackbarManager';
import DetailImage from '../../StyledComponents/DetailImage';

export interface IUploadImageProps {
    emptyImageState: JSX.Element;
    invalid: boolean;
    onChange: (thumbnailBlobUrl: string, imageToUpload: File | null) => void;
    wrapperSx?: SxProps;
    detailImageSx?: SxProps;
    value: string;
}

const AddImageContainer = styled('div')<{ isDragging: boolean, isError: boolean; }>(({ theme, isDragging, isError }) => ({
    width: '100%',
    borderRadius: theme.shape.borderRadius * 4.5,
    border: '2px solid transparent',

    ':hover': {
        border: `2px solid ${theme.palette.background.paper}`,
        cursor: 'pointer',

        ...(isDragging && {
            boxShadow: `0 0 4px ${theme.palette.primary.main}`,
            animation: 'pulsate 2s infinite ease-in-out',
            border: `2px solid ${theme.palette.primary.main}`,
        }),

        ...(isError && {
            boxShadow: `0 0 4px ${theme.palette.error.dark}`,
            border: `2px solid ${theme.palette.error.main}`,
        }),
    },

    ...(isError && {
        boxShadow: `0 0 4px ${theme.palette.error.dark}`,
        border: `2px solid ${theme.palette.error.main}`,
    }),

    '@keyframes pulsate': {
        '0%': {
            boxShadow: `0 0 8px ${theme.palette.primary.main}`,
        },
        '50%': {
            boxShadow: `0 0 12px ${theme.palette.primary.main}`,
        },
        '100%': {
            boxShadow: `0 0 8px ${theme.palette.primary.main}`,
        }
    }
}));

export default function UploadImage(props: IUploadImageProps) {
    const { emptyImageState, value, onChange, invalid, wrapperSx, detailImageSx } = props;

    const [isDragging, setIsDragging] = useState<boolean>(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    async function handleConvertBlobAndChangeValue(imageToUpload: File) {
        const thumbnailBlobUrl = await new Promise<string>(resolve => {
            const reader = new FileReader();

            reader.readAsDataURL(imageToUpload as File);
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
        });

        onChange(thumbnailBlobUrl, imageToUpload);
    }

    async function handleChangeImage(event: React.ChangeEvent<HTMLInputElement>) {
        try {
            const uploadedPhoto = event.target.files?.[0];

            if (uploadedPhoto) {
                await handleConvertBlobAndChangeValue(uploadedPhoto);
            }
        } catch (error) {
            SnackbarManager.showError(error);
        }
    }

    async function handleImageDrop(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        try {
            let thumbnail: File | undefined = undefined;

            const items = event.dataTransfer.items;
            const files = event.dataTransfer.files;

            if (items) {
                const item = items[0];

                if (item.kind === 'file') {
                    thumbnail = item.getAsFile() ?? undefined;
                }
            } else {
                thumbnail = files[0];
            }

            if (thumbnail) {
                await handleConvertBlobAndChangeValue(thumbnail);
            }
        } catch (error) {
            SnackbarManager.showError(error);
        } finally {
            setIsDragging(false);
        }
    }

    function handleImageDragOver(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
    }

    function handleFileUploadDragEnter() {
        setIsDragging(true);
    }

    function handleFileUploadDragLeave() {
        setIsDragging(false);
    }

    function handleImageClick() {
        fileInputRef.current?.click();
    }

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            fileInputRef.current?.click();
        }
    }

    function handleDeleteImage() {
        onChange('', null);
    }

    return (
        <Box sx={wrapperSx}>
            <div
                onDrop={handleImageDrop}
                onDragOver={handleImageDragOver}
                onDragEnter={handleFileUploadDragEnter}
                onDragLeave={handleFileUploadDragLeave}
                onClick={handleImageClick}
                onKeyDown={handleKeyDown}
            >
                <input ref={fileInputRef} type='file' style={{ display: 'none' }} onChange={handleChangeImage} />

                {value
                    ? <AddImageContainer
                        isDragging={isDragging}
                        isError={invalid}
                        tabIndex={0}
                    >
                        <DetailImage src={value} alt='Image of media' sx={detailImageSx} />
                    </AddImageContainer>
                    : <AddUploadImageEmptyState
                        isDragging={isDragging}
                        isError={invalid}
                        emptyState={emptyImageState}
                    />
                }
            </div>

            {value &&
                <Button
                    onClick={handleDeleteImage}
                    variant='outlined'
                    sx={{ mt: 2 }}
                >
                    Afbeelding verwijderen
                </Button>
            }
        </Box>
    );
}