import { styled } from '@mui/material';
import { motion } from 'framer-motion';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5, 0, 0),
}));

const Visual = styled(motion.svg)(({ theme }) => ({
    width: '100%',
    maxWidth: 240,
    margin: theme.spacing(0, 0, 3),

    [theme.breakpoints.down('lg')]: {
        maxWidth: 160,
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: 120,
    }
}));

const EmptyImageContainer = styled('div')<{ isDragging: boolean, isError: boolean; }>(({ theme, isDragging, isError }) => ({
    alignItems: 'center',
    background: theme.palette.grey[600],
    display: 'flex',
    justifyContent: 'center',
    height: 380,
    borderRadius: theme.shape.borderRadius * 4,

    [theme.breakpoints.down('lg')]: {
        height: 320
    },
    [theme.breakpoints.down('sm')]: {
        height: 250
    },

    ':hover': {
        border: `2px solid ${theme.palette.background.paper}`,
        cursor: 'pointer',

        //TODO: fix dragging style bug...
        ...(isDragging && {
            boxShadow: `0 0 4px ${theme.palette.primary.main}`,
            animation: 'pulsate 2s infinite ease-in-out',
            border: `1px solid ${theme.palette.primary.main}`,
        }),

        ...(isError && {
            boxShadow: `0 0 4px ${theme.palette.error.dark}`,
            border: `1px solid ${theme.palette.error.main}`,
        }),
    },

    ...(isError && {
        boxShadow: `0 0 4px ${theme.palette.error.dark}`,
        border: `1px solid ${theme.palette.error.main}`,
    }),

    '@keyframes pulsate': {
        '0%': {
            boxShadow: `0 0 8px ${theme.palette.primary.main}`,
        },
        '50%': {
            boxShadow: `0 0 12px ${theme.palette.primary.main}`,
        },
        '100%': {
            boxShadow: `0 0 8px ${theme.palette.primary.main}`,
        }
    }
}));

interface IProps {
    isError: boolean;
    isDragging: boolean;
    emptyState: JSX.Element;
}

export default function AddUploadImageEmptyState(props: IProps) {
    const { isDragging, isError, emptyState } = props;

    return (
        <EmptyImageContainer isDragging={isDragging} isError={isError} tabIndex={0}>
            <Root >
                <Visual id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 465 423'>
                    {emptyState}
                </Visual>
            </Root>
        </EmptyImageContainer>
    );
}
