import { PropsWithChildren } from 'react';
import LoadingIndicator from './LoadingIndicator/LoadingIndicator';

interface IProps {
    isLoading: boolean;
    height?: string | number;
}

export default function LoadingContainer(props: PropsWithChildren<IProps>) {
    const { children, isLoading, height = '60vh' } = props;

    if (isLoading) {
        return <LoadingIndicator height={height} />;
    }

    return <>{children}</>;
}
