import { Components, Theme, tooltipClasses } from '@mui/material';

export const overrideMuiTooltip = (theme: Theme): Components['MuiTooltip'] => {
    return {
        styleOverrides: {
            tooltip: {
                backgroundColor: theme.palette.grey[600],
                [`& .${tooltipClasses.arrow}`]: {
                    color: theme.palette.grey[600]
                }
            }
        }
    };
};