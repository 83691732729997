import { Components, Theme } from '@mui/material';

export const overrideMuiChip = (theme: Theme): Components['MuiChip'] => {
    return {
        styleOverrides: {
            root: {
                height: 'unset',
                fontWeight: theme.typography.fontWeightMedium,
                borderRadius: theme.shape.borderRadius * 2,
            },
            sizeSmall: {
                padding: theme.spacing(.5, 0),
            },
            sizeMedium: {
                padding: theme.spacing(1, .5),
            }
        }
    };
};