const routeHelper = {
    practicalInfo: {
        toOverview: () => '/practical-info',
        toEditItem: (practicalInfoId: string) => `/practical-info/${practicalInfoId}/edit`,
    },
    // TODO: Refactor unnecessary arrow funtions to strings
    management: {
        toOverview: () => '/management',
        teams: {
            toOverview: () => '/management/teams',
            toSingleItem: (teamId: string, edit: boolean = false) => `/management/teams/${teamId}${edit ? '/edit' : ''}`,
            toNewItem: () => '/management/teams/new',
        },
        colleagues: {
            toOverview: '/management/colleagues',
            toSingleItem: (referenceCode: string) => `/management/colleagues/${referenceCode}/edit`,
            toNewItem: '/management/colleagues/new',
        },
        learningPathTemplates: {
            toOverview: '/management/learning-path-templates',
            toSingleItem: (templateId: string) => `/management/learning-path-templates/${templateId}/edit`,
            toNewItemStep: (templateId: string) => `/management/learning-path-templates/${templateId}/edit/step/new`,
            toSingleItemStep: (templateId: string, templateStepId: string) => `/management/learning-path-templates/${templateId}/edit/step/${templateStepId}`,
        },
        feedback: {
            overview: {
                toOverview: '/management/feedback/overview',
                toSingleItem: (referenceCode: string, templateId: string) => `/management/feedback/overview/${referenceCode}/${templateId}`,
            },
            templates: {
                toOverview: '/management/feedback/templates',
                toSingleItem: (templateId: string) => `/management/feedback/templates/${templateId}/edit`,
            },
        }
    },
    teams: {
        toOverview: '/teams',
        toSingleTeam: (teamId: string) => `/teams/${teamId}`,
        toSingleTeamUser: (teamId: string, userId: string) => `/teams/${teamId}/user/${userId}`,
        toTeamVacationSchedule: (teamId: string, year?: number) => `/teams/${teamId}/vacation/${year ?? ''}`,
        toNewTeamVacationItem: (teamId: string) => `/teams/${teamId}/vacation/new`,
    },
    profile: {
        toProfile: '/profile',
        toAboutMe: '/profile/about-me',
    },
    hoursRegistration: {
        toOverview: (referenceCode: string) => `/hour-registration/${referenceCode}`,
        toNewItem: (referenceCode: string) => `/hour-registration/${referenceCode}/new/edit`,
        toSingleItem: (referenceCode: string, hoursRegistrationId: number) => `/hour-registration/${referenceCode}/${hoursRegistrationId}/edit`,
        toSlideUpDialog: (referenceCode: string, hoursRegistrationId: number) => `/hour-registration/${referenceCode}/${hoursRegistrationId}/actions-dialog`,
    },
    learn: {
        toOverview: '/learn',
        books: {
            toOverview: '/learn/books',
            toSingleItem: (bookId: string) => `/learn/books/${bookId}/view`,
            toNewItem: '/learn/books/new/edit',
            toEditItem: (bookId: string) => `/learn/books/${bookId}/edit`,
        },
        videos: {
            root: 'learn/videos',
            toOverview: '/learn/videos',
            toSingleVideo: (videoId: string) => `/learn/videos/${videoId}/view`,
            toNewItem: '/learn/videos/new/edit',
            toEditVideo: (mediaId: string) => `/learn/videos/${mediaId}/edit`,
            reviews: {
                toReviews: (videoId: string) => `/learn/videos/${videoId}/view/reviews`,
            }
        },
        podcasts: {
            toOverview: '/learn/podcasts',
            toSinglePodcast: (podcastId: string) => `/learn/podcasts/${podcastId}/view`,
            toNewItem: '/learn/podcasts/new/edit',
            toEditPodcast: (mediaId: string) => `/learn/podcasts/${mediaId}/edit`,
            reviews: {
                toReviews: (podcastId: string) => `/learn/podcasts/${podcastId}/view/reviews`
            }
        },
        learningPaths: {
            toUserOverview: (referenceCode: string) => `/learn/learning-paths/${referenceCode}`,
            single: {
                view: (referenceCode: string, learningPathId: string, atFirstTab: boolean) => `/learn/learning-paths/${referenceCode}/${learningPathId}/${atFirstTab ? 'to-do' : 'finished'}`,
                edit: {
                    toOverview: (referenceCode: string, learningPathId: string, atFirstTab: boolean) => `/learn/learning-paths/${referenceCode}/${learningPathId}/${atFirstTab ? 'to-do' : 'finished'}/edit`,
                    toEditLearningPathStep: (referenceCode: string, learningPathId: string, atFirstTab: boolean, stepId: string) => `/learn/learning-paths/${referenceCode}/${learningPathId}/${atFirstTab ? 'to-do' : 'finished'}/edit/step/${stepId}`,
                    toNewLearningPathStep: (referenceCode: string, learningPathId: string, atFirstTab: boolean) => `/learn/learning-paths/${referenceCode}/${learningPathId}/${atFirstTab ? 'to-do' : 'finished'}/edit/step/new`,
                },
                viewStep: (referenceCode: string, learningPathId: string, atFirstTab: boolean, stepId: string) => `/learn/learning-paths/${referenceCode}/${learningPathId}/${atFirstTab ? 'to-do' : 'finished'}/${stepId}/view`,
            },
            create: {
                toNewLearningPathOverview: (referenceCode: string) => `/learn/learning-paths/${referenceCode}/edit`,
                toNewLearningPath: (referenceCode: string, learningPathId: string) => `/learn/learning-paths/${referenceCode}/edit/${learningPathId}`,
                toNewLearningPathStep: (referenceCode: string, learningPathId: string) => `/learn/learning-paths/${referenceCode}/edit/${learningPathId}/step/new`,
                toEditLearningPathStep: (referenceCode: string, learningPathId: string, stepId: string) => `/learn/learning-paths/${referenceCode}/edit/${learningPathId}/step/${stepId}`,
            },
        },
        personalGoals: {
            toOverview: (referenceCode: string) => `/learn/personal-goals/${referenceCode}`,
            toSinglePersonalGoal: (referenceCode: string, personalGoalId: string) => `/learn/personal-goals/${referenceCode}/${personalGoalId}/view`,
            toNewPersonalGoal: (referenceCode: string) => `/learn/personal-goals/${referenceCode}/new/edit`,
            toEditPersonalGoal: (referenceCode: string, personalGoalId: string) => `/learn/personal-goals/${referenceCode}/${personalGoalId}/edit`,
        }
    },
    feedback: {
        toOverview: '/feedback',
        toNewFeedback: '/feedback/new',
        toSingleRequest: (feedbackRequestId: string) => `/feedback/request/${feedbackRequestId}`,
        toFeedbackItem: (feedbackItemId: string) => `/feedback/received/${feedbackItemId}`,
        toNewFeedbackRequest: (templateId: string) => `/feedback/request/add/${templateId}`,
    }
};

export default routeHelper;
