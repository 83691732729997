import { AddOrUpdateLearningPathStep, AddPersonalLearningPath, LearningPathIconType, LearningPathStatus, LearningPathStep, LearningPathStepContent, LearningPathStepContentType, LearningPathStepSummary, LearningPathTemplateSummary, PersonalLearningPath, UpdatePersonalLearningPath } from '../../../../../../ApiClients/ApiClient';
import { Api } from '../../../../../../ApiClients/clients';
import { generateUuid } from '../../../../../../Helpers/uuidHelpers';
import { IEditablePersonalLearningPath } from '../../Logic/LearningPathsStoreLogic';
import { ILearningPath, ILearningPathStep } from '../../Definitions/learningPathDefinitions';
import routeHelper from '../../../../../../Helpers/routeHelper/routeHelper';
import LearningPathLogic from '../../Logic/LearningPathLogic';
import { ILearningPathEdit } from '../Edit/Components/IndividualLearningPathEdit';
import { IUser } from '../../../../../../Definitions/userDefinitions';

export default class IndividualLearningPathLogic {
    static mapILearningPathToAddPersonalLearningPath(learningPath: ILearningPath, userId: string): AddPersonalLearningPath {
        return {
            id: learningPath.id,
            title: learningPath.title,
            description: learningPath.description ?? '',
            iconType: learningPath.iconType,
            steps: learningPath.steps.map(IndividualLearningPathLogic.mapLearningPathStepToAddOrUpdateLearningPathStep),
            userId,
        };
    }

    static mapILearningPathToUpdatePersonalLearningPath(learningPath: ILearningPath): UpdatePersonalLearningPath {
        return {
            title: learningPath.title,
            description: learningPath.description ?? '',
            iconType: learningPath.iconType,
            steps: learningPath.steps.map(IndividualLearningPathLogic.mapLearningPathStepToAddOrUpdateLearningPathStep),
        };
    }

    static mapLearningPathStepToAddOrUpdateLearningPathStep(step: LearningPathStep): AddOrUpdateLearningPathStep {
        if (step.content === null || step.content === undefined) {
            return step;
        }

        return {
            ...step,
            contentId: step.content.id,
            contentType: step.content?.contentType ?? LearningPathStepContentType.Book,
        };
    }

    static getNewLearningPath(): LearningPathTemplateSummary {
        return {
            id: 'new',
            title: 'Leeg leerpad',
            iconType: LearningPathIconType.Console,
            status: LearningPathStatus.Draft,
            specialties: [],
            numberOfSteps: 0,
        };
    }

    static getEmptyIndividualLearningPath(): PersonalLearningPath {
        return {
            id: generateUuid(),
            title: '',
            description: '',
            iconType: LearningPathIconType.Console,
            userId: '',
            steps: [
                {
                    id: '',
                    title: '',
                    stepNumber: 0
                }
            ],
        };
    }

    static getEmptyEditableIndividualLearningPath(): IEditablePersonalLearningPath {
        return {
            id: generateUuid(),
            title: '',
            description: '',
            iconType: LearningPathIconType.Console,
            userId: '',
            steps: []
        };
    }

    static getEmptyLearningPathContent(contentType: LearningPathStepContentType): LearningPathStepContent {
        return {
            id: '',
            contentType,
            imageUrl: '',
            subtitle: '',
            title: ''
        };
    }

    static getEmptyLearningPathStep(): LearningPathStep {
        return {
            id: generateUuid(),
            title: '',
            description: '',
            stepNumber: 0,
        };
    }

    static async addIndividualLearningPath(learningPath: ILearningPath, userId: string): Promise<AddPersonalLearningPath> {
        const addLearningPath: AddPersonalLearningPath = IndividualLearningPathLogic.mapILearningPathToAddPersonalLearningPath(learningPath, userId);
        await Api.addPersonalLearningPath(addLearningPath);

        return addLearningPath;
    }

    static async updateIndividualLearningPath(formData: ILearningPathEdit, selectedSteps: ILearningPathStep[], individualLearningPathId: string, selectedUser: IUser): Promise<ILearningPath> {
        const individualLearningPathToUpdate = await LearningPathLogic.updateImagesFromLearningPath(formData, selectedSteps, individualLearningPathId, selectedUser);

        const updateIndividualLearningPath: UpdatePersonalLearningPath = IndividualLearningPathLogic.mapILearningPathToUpdatePersonalLearningPath(individualLearningPathToUpdate);
        await Api.updatePersonalLearningPath(individualLearningPathId, updateIndividualLearningPath);

        return individualLearningPathToUpdate;
    }

    static ableToFinishStep(steps: LearningPathStepSummary[], currentStepNumber: number) {
        return steps.some(s => (s.stepNumber < currentStepNumber) && s.mandatory && typeof s.finishedAt !== 'string');
    }

    static navigateToExtraContent(contentType: LearningPathStepContentType, id: string) {
        switch (contentType) {
            case LearningPathStepContentType.Media:
                return routeHelper.learn.videos.toSingleVideo(id);
            case LearningPathStepContentType.Book:
                return routeHelper.learn.books.toSingleItem(id);
        }
    }

    static getNextStep(steps: LearningPathStepSummary[], currentStepNumber: number) {
        const currentIndex = steps.findIndex(s => s.stepNumber === currentStepNumber);
        return steps[currentIndex + 1];
    }

    static getPrevStep(steps: LearningPathStepSummary[], currentStepNumber: number) {
        const currentIndex = steps.findIndex(s => s.stepNumber === currentStepNumber);
        return steps[currentIndex - 1];
    }
}