import { Input, InputProps, styled } from '@mui/material';

const StyledInput = styled(Input)(({ theme }) => ({
    background: theme.palette.grey[500],
    borderRadius: theme.shape.borderRadius * 2,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    width: '100%',

    'input::placeholder': {
        opacity: .3,
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },

    '&&:hover::before, &::after, &:before': {
        borderBottom: 'none',
    },
}));

export default function StyledSearchInput(props: InputProps) {
    return (
        <StyledInput {...props} />
    );
}
