import { Box, Button, styled, SxProps, Typography, useTheme } from '@mui/material';
import { Content } from '../Content';
import { useNavigate } from 'react-router-dom';
import BackButtonIcon from '@mui/icons-material/KeyboardBackspace';
import CalenderIcon from '@mui/icons-material/Today';
import SearchIcon from '@mui/icons-material/Search';
import StyledSearchInput from '../StyledComponents/StyledSearchInput';

interface ISearchFieldProps {
    value: string;
    onChange: (value: string) => void;
}

interface IProps {
    backgroundColor?: string;
    backButtonAction?: () => void;
    iconRightTop?: boolean;
    searchFieldProps?: ISearchFieldProps;
    sx?: SxProps;
    title: string;
}

const TopbarContainer = styled(Box)<IProps>(({ theme, backgroundColor }) => ({
    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    backgroundColor,
    padding: theme.spacing(2),
}));

const StyledRow = styled('div')({
    display: 'flex',
});

const StyledButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 0),
    minWidth: 0,

    '&:hover': {
        background: 'none',
    },
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
    color: theme.palette.grey[900],
    marginRight: theme.spacing(1),
    opacity: .5,
}));

export default function StyledTitleBar(props: IProps) {
    const { backButtonAction, searchFieldProps, iconRightTop, ...restprops } = props;

    const navigate = useNavigate();
    const theme = useTheme();

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        searchFieldProps?.onChange(e.target.value);
    }

    return (
        <TopbarContainer {...restprops}>
            <Content>
                <StyledRow>
                    <>
                        <StyledButton onClick={backButtonAction ?? (() => navigate(-1))} >
                            <BackButtonIcon sx={{ width: 35, height: 35 }} />
                        </StyledButton>
                        <Typography variant='h2' sx={{ alignSelf: 'center' }}>{props.title}</Typography>
                    </>
                    {iconRightTop && <CalenderIcon sx={{ fontSize: 30 }} />}
                </StyledRow>

                {searchFieldProps &&
                    <StyledSearchInput
                        onChange={handleChange}
                        placeholder='Zoeken'
                        type='search'
                        value={searchFieldProps?.value}
                        startAdornment={
                            <StyledSearchIcon />
                        }
                        sx={{ marginTop: theme.spacing(6) }}
                    />
                }
            </Content>
        </TopbarContainer>
    );
}
