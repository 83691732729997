import { Box, IconButton, Menu, MenuItem, SxProps, styled } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface IProps {
    menuItems: IContextMenuItem[];
    sx?: SxProps;
    isGrey?: boolean;
    paperColor?: string;
}

export interface IContextMenuItem {
    hidden?: boolean;
    icon: ReactNode;
    id: string;
    label: string;
    onClick: () => void;
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    padding: theme.spacing(1.5, 2.25, 1.5, 1.75),

    '&:hover': {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.text.primary,
    },

    '&:focus': {
        backgroundColor: theme.palette.grey[500],
        color: theme.palette.text.primary,
    },

    '& svg': {
        fontSize: 18,
    }
}));

export default function ContextMenu(props: IProps) {
    const { menuItems, sx, isGrey, paperColor } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    function handleOpenMenu(event: MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }

    function handleCloseMenu(event?: any) {
        if (event) {
            event?.stopPropagation();
        }
        setAnchorEl(undefined);
    }

    function handleOnClick(event: React.MouseEvent<HTMLLIElement>, menuItem: IContextMenuItem) {
        menuItem.onClick();
        handleCloseMenu(event);
    }

    return (
        <>
            <IconButton sx={{ opacity: isGrey ? .3 : 'unset', mr: -1 }} size='small' onClick={handleOpenMenu} color='inherit'>
                <MoreVertIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                MenuListProps={{ autoFocus: true }}
                onClose={(event) => handleCloseMenu(event)}
                open={Boolean(anchorEl)}
                slotProps={{ paper: { sx: ({ backgroundColor: paperColor }) } }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {menuItems.map((mi, key) =>
                    <StyledMenuItem
                        disabled={mi.hidden}
                        hidden={mi.hidden}
                        key={key}
                        onClick={(event: MouseEvent<HTMLLIElement>) => handleOnClick(event, mi)}
                        sx={sx}
                    >
                        <Box component='span' sx={{ display: 'inline-flex' }}>{mi.icon}</Box>
                        <Box component='span' sx={{ display: 'inline-flex' }}>{mi.label}</Box>
                    </StyledMenuItem>
                )}
            </Menu>
        </>
    );
}
