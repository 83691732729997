import { Components, Theme } from '@mui/material';

export const overrideMuiContainer = (theme: Theme): Components['MuiContainer'] => {
    return {
        styleOverrides: {
            root: {
                [theme.breakpoints.up('sm')]: {
                    paddingRight: 16,
                    paddingLeft: 16,
                }
            },
        }
    };
};