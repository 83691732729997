import { Box, SxProps, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import theme from '../../../Theme';
import { ReadMoreButton } from './ReadMoreButton';

interface IProps {
    description: string;
    maxTextLength: { default: number, sm?: number };
    readMore?: boolean;
    hideButton?: boolean;
    sx?: SxProps;
    buttonSx?: SxProps;
}

export default function ReadMoreText(props: IProps) {
    const { maxTextLength, description, hideButton, readMore = true, sx, buttonSx } = props;
    const [showFullDescription, setShowFullDescription] = useState(false);

    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const { default: defaultLength, sm: smallLength } = maxTextLength;
    const length = isSmDown ? smallLength ?? defaultLength : defaultLength;

    function handleReadMore(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.stopPropagation();
        setShowFullDescription(prevState => !prevState);
    };

    function handleReadMoreForMdDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (isMdDown && readMore && !hideButton && description.length > length) {
            event.stopPropagation();
            setShowFullDescription(prevState => !prevState);
        }
    };

    return (
        <Box sx={sx} onClick={handleReadMoreForMdDown}>
            {description.length > length
                ? <>
                    {!showFullDescription
                        ? description.slice(0, length) + '...'
                        : description
                    }
                    {readMore && !hideButton &&
                        <ReadMoreButton onClick={handleReadMore} sx={buttonSx}>
                            {!showFullDescription ? 'Lees verder' : 'Minder'}
                        </ReadMoreButton>
                    }
                </>
                : description
            }
        </Box>
    );
}
