import 'dayjs/locale/nl';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import reportWebVitals from './reportWebVitals';
import theme from './Theme';
import 'dayjs/locale/nl';
import 'dayjs/plugin/isBetween';
import 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.locale('nl');
dayjs.extend(isoWeek);
dayjs.extend(dayOfYear);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container!);
    root.render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
