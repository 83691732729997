import { ComponentType, createElement } from 'react';

export default function withContext(contextProvider: ComponentType, component: ComponentType) {
    return () => createElement(
        contextProvider,
        {},
        createElement(
            component
        )
    );
}