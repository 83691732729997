import { Components, formHelperTextClasses, svgIconClasses, Theme } from '@mui/material';

export const overrideMuiTextField = (theme: Theme): Components['MuiTextField'] => {
    return {
        styleOverrides: {
            root: {
                background: theme.palette.grey[500],
                borderRadius: theme.shape.borderRadius,

                '&::placeholder': {
                    color: theme.palette.grey[400],
                    fontSize: 15
                },

                [`& .${formHelperTextClasses.root}`]: {
                    color: theme.palette.error.main,
                    backgroundColor: theme.palette.background.default,
                    margin: 0
                },

                [`& .${svgIconClasses.root}`]: {
                    color: theme.palette.text.primary
                }
            },
        }
    };
};