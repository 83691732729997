import { AddUserHobby, AddUser, MasterData, RoleType, UpdateUser, UpdateUserAsAdmin, User, UserHobby, UserSummary } from '../ApiClients/ApiClient';
import { Api } from '../ApiClients/clients';
import { generateUuid } from '../Helpers/uuidHelpers';
import { IUser, IUserSummary } from '../Definitions/userDefinitions';
import dayjs from 'dayjs';

export default class UsersLogic {
    /**
     * Tries to get the user by id, returns the user if found otherwise an error will be thrown.
     */
    static getUserById(userId: string, users: IUser[]): IUser {
        const user = users.find(u => u.id === userId); 

        if (!user) {
            throw new Error(`User with id: ${userId} was not found`);
        }

        return user;
    }

    private static getBuddyFullName(user: User): string | undefined {
        if (!user.buddyId) {
            return undefined;
        }

        return `${user.buddyFirstName} ${user.buddyLastName}`;
    }

    static getFullName(user: User | UserSummary): string {
        return `${user.firstName} ${user.lastName} ${dayjs(user.outOfService).isBefore() ? '(uit dienst)' : ''}`;
    }

    static getEmptyUser(): IUser {
        return {
            id: generateUuid(),
            firstName: '',
            lastName: '',
            email: '',
            fullName: '',
            teamId: '',
            role: RoleType.User,
            startDate: '',
            referenceCode: '',
            yearsEmployed: 0,
            teams: [],
            isDev: false,
            isFeedbackManager: false,
        };
    }

    static mapUserToIUser(user: User): IUser {
        return {
            ...user,
            fullName: UsersLogic.getFullName(user),
            buddyFullName: UsersLogic.getBuddyFullName(user),
            yearsEmployed: UsersLogic.getYearsEmployed(user.startDate),
        };
    }

    static mapUserSummaryToIUserSummary(userSummary: UserSummary): IUserSummary {
        return {
            ...userSummary,
            fullName: UsersLogic.getFullName(userSummary),
        };
    }

    static mapIUserToMasterData(user: IUser): MasterData {
        return {
            code: user.referenceCode,
            id: Number(user.id),
            name: user.fullName
        };
    }

    static mapUserToUpdateUser(user: User): UpdateUser {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            bio: user.bio,
            message: user.message,
            birthDate: user.birthDate,
            address: user.address,
            city: user.city,
            postalCode: user.postalCode,
            phoneNumber: user.phoneNumber,
            profilePicture: user.profilePicture,
            referenceCode: user.referenceCode,
            buddyId: user.buddyId,
            teamId: user.teamId,
            jobTitle: user.jobTitle,
            startDate: user.startDate,
            onboardingLink: user.onboardingLink,
        };
    }

    static mapUserToUpdateUserAsAdmin(user: User): UpdateUserAsAdmin {
        const mappedToUpdateUser = UsersLogic.mapUserToUpdateUser(user);

        return {
            ...mappedToUpdateUser,
            referenceCode: user.referenceCode,
            role: user.role,
            outOfService: user.outOfService,
        };
    }

    static mapUserToAddUser(user: User): AddUser {
        return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            bio: user.bio,
            birthDate: user.birthDate,
            address: user.address,
            city: user.city,
            postalCode: user.postalCode,
            phoneNumber: user.phoneNumber,
            referenceCode: user.referenceCode,
            buddyId: user.buddyId,
            teamId: user.teamId,
            jobTitle: user.jobTitle,
            startDate: user.startDate,
            onboardingLink: user.onboardingLink ?? generateUuid(),
            role: user.role,
        };
    }

    static getYearsEmployedLabel(user: IUser) {
        return user.yearsEmployed > 0
            ? user.yearsEmployed + ' jaar'
            : 'Minder dan 1 jaar';
    }

    static async fetchUserById(userId: string): Promise<IUser> {
        const user = UsersLogic.mapUserToIUser(await Api.getUser(userId));

        return user;
    }

    static async fetchUserByEmail(email: string): Promise<IUser> {
        const userResponse = UsersLogic.mapUserToIUser(await Api.getUserByEmail(email));
        return userResponse;
    }

    static async fetchHobbiesFromUser(userId: string): Promise<UserHobby[]> {
        const userHobbies = await Api.getUserHobbiesByUserId(userId);
        return userHobbies;
    }

    static async deleteUserHobby(userId: string, hobbyId: string): Promise<void> {
        await Api.deleteUserHobby(userId, hobbyId);
    }

    static async addHobby(userId: string, hobbyId: string, hobbyName: string): Promise<void> {
        const userHobbyToAdd: AddUserHobby = { userId: userId, hobbyId: hobbyId, name: hobbyName };

        await Api.addUserHobby(userHobbyToAdd);
    }

    // TODO: rename method name and refactor code
    static userRoleIsModerator(user: IUser): boolean {
        const { role } = user;

        if (RoleType[role] === RoleType.Admin) {
            return true;
        } else {
            return false;
        }
    }

    static async updateUserImageFromGoogle(user: IUser, profilePicture: string): Promise<void> {
        const userWithGoogleImage: IUser = { ...user, profilePicture: profilePicture.slice(0, -6) };
        const updatedUser: UpdateUser = UsersLogic.mapUserToUpdateUser(userWithGoogleImage);

        await Api.updateUser(user.id, updatedUser);
    }

    static async fetchCurrentlyLoggedInUser(): Promise<IUser> {
        // TODO: make sure to get buddy data as well. At least id, first/last name and profile picture
        const loggedInUser = await Api.getCurrentLoggedInUser();
        return UsersLogic.mapUserToIUser(loggedInUser);
    }

    static async fetchAllUsers(): Promise<IUser[]> {
        const users = await Api.getUsers(false);
        return users.map(UsersLogic.mapUserToIUser);
    }

    private static getYearsEmployed(startDate: string) {
        const yearsEmployed = dayjs().diff(startDate, 'year', true);
        return Math.round(yearsEmployed * 2) / 2;
    }
}