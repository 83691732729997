import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IProps {
    color: string;
}

const Root = styled('span')<Partial<IProps>>(({ color }) => ({
    color
}));

export default function TextWithColor(props: PropsWithChildren<IProps>) {
    const { color, children } = props;

    return (
        <Root color={color}>
            {children}
        </ Root>
    );
}