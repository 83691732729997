import { Api } from '../../../../../ApiClients/clients';
import { generateUuid } from '../../../../../Helpers/uuidHelpers';
import { ILearningPath, ILearningPathStep } from '../Definitions/learningPathDefinitions';
import { ILearningPathEdit } from '../Individual/Edit/Components/IndividualLearningPathEdit';
import { IUser } from '../../../../../Definitions/userDefinitions';
import { LearningPathTemplate } from '../../../../../ApiClients/ApiClient';
import UploadImageHelper from '../../../../../Helpers/UploadImageHelper';

export default class LearningPathLogic {
    static mapLearningPathTemplateToILearningPath(template: LearningPathTemplate, userId: string): ILearningPath {
        return {
            id: template.id,
            title: template.title,
            description: template.description,
            status: template.status,
            eligibleToEdit: template.createdByUserId === userId,
            iconType: template.iconType,
            specialties: template.specialties,
            userId: userId,
            steps: template.steps ?? [],
        };
    }

    static mapIEditableToILearningPath(learningPathEdit: ILearningPathEdit, learningPathId: string, userId: string, selectedLearningPathSteps: ILearningPathStep[]): ILearningPath {
        const { description, iconType, title } = learningPathEdit;

        return {
            description,
            iconType,
            steps: selectedLearningPathSteps,
            id: learningPathId,
            title,
            userId,
        };
    }

    static async uploadLearningPathImages(learningPath: ILearningPath, isFromTemplate: boolean): Promise<ILearningPath> {
        for (const step of learningPath.steps) {
            if (step.imageToUpload || (isFromTemplate && step.coverImage)) {
                let imageUrl;

                if (step.imageToUpload) {
                    imageUrl = await Api.uploadImage({ fileName: generateUuid(), data: step.imageToUpload });
                } else if (isFromTemplate && step.coverImage) {
                    const coverImageId = UploadImageHelper.getImageIdFromBlobUrl(step.coverImage);
                    if(coverImageId){
                        imageUrl = await Api.copyImage(coverImageId);
                    }
                }

                const coverImage = `${imageUrl}?${Date.now()}` ?? step.coverImage;

                const index = learningPath.steps.findIndex(s => s.id === step.id);
                learningPath.steps[index] = { ...step, coverImage };
            }
        }

        return learningPath;
    }

    static async updateImagesFromLearningPath(learningPathEdit: ILearningPathEdit, selectedSteps: ILearningPathStep[], individualLearningPathId: string, selectedUser: IUser): Promise<ILearningPath> {
        const individualLearningPathToUpdate = await LearningPathLogic.uploadLearningPathImages(
            LearningPathLogic.mapIEditableToILearningPath(learningPathEdit, individualLearningPathId, selectedUser.id, selectedSteps),
            false
        );

        return individualLearningPathToUpdate;
    }
}