import { Components, Theme } from '@mui/material';

export const overrideMuiMenu = (theme: Theme): Components['MuiMenu'] => {
    return {
        styleOverrides: {
            list: {
                padding: 0,
            }
        }
    };
};