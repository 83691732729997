import { Bubble, BulbIcon, PageImageContainer, TextWithColor } from '../../../../Components';
import { OnboardingNavigateNextButton } from '../Components';
import { styled, Typography } from '@mui/material';
import { usePageTitle } from '../../../../Hooks';
import theme from '../../../../Theme';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom } from '../../Logic/onboardingState';

const AlignCenterContainer = styled('div')({
    textAlign: 'center'
});

const Root = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, 0)
}));

export default function OnboardingLearningPathPage() {
    const { user } = useAtomValue(onboardingStateAtom);

    usePageTitle(`${user.jobTitle} - Onboarding`);

    return (
        <>
            <PageImageContainer backgroundcolor={theme.palette.primary.main}>
                <Bubble size={286} right={-100} top={-100} />
                <Bubble size={160} left={-25} bottom={50} />
                <Bubble size={124} right={0} bottom={-75} />

                <AlignCenterContainer>
                    <BulbIcon sx={{ zIndex: 1, color: theme.palette.grey[800], fontSize: 128 }} />
                    <Typography variant='h2' sx={{ zIndex: 1, color: theme.palette.grey[800] }}>{user.jobTitle}</Typography>
                </AlignCenterContainer>
            </PageImageContainer>

            <Root>
                <Typography variant='h2'>Jouw learning path</Typography>
                <Typography variant='body1' sx={{ margin: theme.spacing(1, 0) }}>
                    Annie gaat jou trainen tot product professional. Lees relevante <TextWithColor color={theme.palette.primary.main}>boeken</TextWithColor>, volg <TextWithColor color={theme.palette.primary.main}>trainingen</TextWithColor> en doorloop het door Annie samengestelde <TextWithColor color={theme.palette.primary.main}>programma</TextWithColor>.
                </Typography>
                <Typography variant='body1'>Neem rustig de tijd, en schroom niet om je buddy om advies of tips te vragen!</Typography>
            </Root>

            <OnboardingNavigateNextButton />
        </>
    );
}
