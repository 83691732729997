import { styled } from '@mui/material';

export default styled('img')(({ theme }) => ({
    width: '100%',
    maxHeight: 380,
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: theme.shape.borderRadius * 4,
    verticalAlign: 'middle',

    [theme.breakpoints.down('lg')]: {
        maxHeight: 320
    },
    [theme.breakpoints.down('sm')]: {
        maxHeight: 250
    }
}));