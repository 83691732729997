import { Box, SxProps, TextField, autocompleteClasses, buttonBaseClasses, chipClasses, outlinedInputClasses, styled } from '@mui/material';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { FormGroupErrorText } from '.';
import { IFormGroupProps } from './Definitions/formGroupDefinitions';
import { ReactNode } from 'react';
import FormGroupCharacterCounter from './FormGroupCharacterCounter';

export interface IMultiLineProps {
    multiLine: boolean;
    rows?: number;
}

interface IProps<T extends FieldValues> extends IFormGroupProps<T> {
    sx?: SxProps;
    multiLineProps?: IMultiLineProps;
    readOnly?: boolean;
    maxCharacters?: number;
    isLight?: boolean;
    isNumber?: boolean;
    tooltipText?: string;
    autoFocus?: boolean;
    endAdornment?: ReactNode;
}

export const StyledTextfield = styled(TextField, { shouldForwardProp: p => p !== 'isLight' })<{ isLight?: boolean }>(({ theme, isLight }) => ({
    [`& .${outlinedInputClasses.root}`]: {
        '& fieldset': {
            borderColor: isLight ? theme.palette.grey[200] : 'none',
        },
        '&:hover fieldset': {
            borderColor: isLight ? theme.palette.grey[800] : 'none',
        },
        '&:hover.Mui-error > fieldset': {
            borderColor: isLight ? theme.palette.error.dark : 'none',
        },
        '&:hover:focus-within.Mui-error > fieldset': {
            borderColor: isLight ? theme.palette.error.dark : 'none',
        },
        '&:hover:focus-within fieldset': {
            borderColor: isLight ? theme.palette.primary.main : 'none',
        },
    },
    [`& .${chipClasses.root}`]: {
        borderRadius: theme.shape.borderRadius * 3,
        backgroundColor: theme.palette.grey[600],
        fontFamily: 'inherit',
    },
    [`& .${buttonBaseClasses.root}.${chipClasses.root}`]: {
        [`& .${chipClasses.deleteIcon}`]: {
            color: theme.palette.grey[100]
        },
    },
    [`& .${autocompleteClasses.endAdornment}`]: {
        position: 'absolute',
        top: 'unset',
        bottom: theme.spacing(1.6),
        left: 'fit-content',
    },
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },

    '& :-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.grey[500]} inset`,
        WebkitTextFillColor: theme.palette.text.primary,
        WebkitBorderRadius: 0,
        caretColor: theme.palette.text.primary
    },

    '& input[type="number"]::-webkit-inner-spin-button': {
        webkitAppearance: 'none',
        padding: 4,
        width: 14,
        position: 'absolute',
        top: 1,
        right: 1,
        bottom: 1,
    }

}));

export default function DefaultFormGroup<T extends FieldValues>(props: IProps<T>) {
    const {
        sx,
        control,
        disabled,
        name,
        rules,
        label,
        placeholder,
        fullWidth,
        multiLineProps,
        readOnly,
        maxCharacters,
        isLight,
        isNumber,
        tooltipText,
        autoFocus,
        endAdornment,
    } = props;

    const formLabel = label?.toString();

    const renderInput: ControllerProps<T>['render'] = props => {
        const { field, fieldState: { error, invalid } } = props;

        return (
            <Box sx={sx}>
                <FormGroupCharacterCounter
                    label={formLabel}
                    maxCharacters={maxCharacters}
                    value={field.value}
                    isLight={isLight}
                    rules={rules}
                    tooltipText={tooltipText}
                />

                <StyledTextfield
                    {...field}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    type={isNumber ? 'number' : 'text'}
                    error={invalid}
                    isLight={isLight}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    multiline={multiLineProps?.multiLine}
                    value={field.value ?? ''}
                    minRows={multiLineProps?.rows ?? ''}
                    inputProps={{
                        type: isNumber ? 'number' : 'unset',
                        min: isNumber ? 0 : 'unset',
                        step: isNumber ? 0.25 : 'unset'
                    }}
                    InputProps={{
                        readOnly: readOnly,
                        sx: theme => ({
                            '& input': {
                                color: readOnly ? theme.palette.primary.main : theme.palette.text.primary
                            },
                            bgcolor: isLight ? theme.palette.text.primary : 'unset',
                            color: isLight ? theme.palette.text.secondary : 'unset',
                        }),
                        endAdornment,
                    }}
                />

                <FormGroupErrorText error={invalid}>
                    {error?.message ?? ''}
                </FormGroupErrorText>
            </Box>
        );
    };

    return (
        <Controller
            control={control}
            name={name}
            render={renderInput}
            rules={rules}
        />
    );
}
