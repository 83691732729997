import { IRefreshToken, IAuthToken } from '../Modules/Login/Definitions/LoginDefinitions';
import jwtDecode from 'jwt-decode';

export default class AuthLogic {
    static getAuthToken() {
        return localStorage.getItem('authToken');
    }

    static getRefreshToken() {
        return localStorage.getItem('refreshToken');
    }

    static hasAuthTokens() {
        const authToken = AuthLogic.getAuthToken();
        const refreshToken = AuthLogic.getRefreshToken();

        return authToken && refreshToken;
    }

    static isTokenExpired(token: string | null | undefined) {
        if (!token) {
            return true;
        }

        const tokenPayload: IRefreshToken | IAuthToken = jwtDecode(token);
        return new Date(tokenPayload.exp * 1000) < new Date();
    }
}