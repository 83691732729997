import { DateHelper } from '../../../../Helpers';
import { OnboardingNavigateNextButton } from '../Components';
import { PageImageContainer, TextWithColor } from '../../../../Components';
import { styled, Typography } from '@mui/material';
import { usePageTitle } from '../../../../Hooks';
import step1onboardingImage from '../Resources/step1onboarding.jpg';
import theme from '../../../../Theme';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom } from '../../Logic/onboardingState';

const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(3, 0)
}));

export default function OnboardingIntroPage() {
    const { user } = useAtomValue(onboardingStateAtom);

    usePageTitle('Welkom - Onboarding');

    return (
        <>
            <PageImageContainer sx={{ display: 'flex' }}>
                <img src={step1onboardingImage} alt='' />
            </PageImageContainer>

            <Root>
                <Typography variant='h2'>{user.firstName},</Typography>
                <Typography sx={{ margin: theme.spacing(1, 0) }} variant='body1'>Welkom bij het team. Geniet nog even van je vrije dag, want <TextWithColor color={theme.palette.primary.main}>{DateHelper.getDayOfTheWeek(new Date(user.startDate).getDay())}</TextWithColor> gaan we knallen!</Typography>
                <Typography sx={{ marginTop: theme.spacing(2) }} variant='body1'>Swipe → om te zien wat je kunt verwachten</Typography>
            </Root>

            <OnboardingNavigateNextButton />
        </>
    );
}