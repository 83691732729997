import { styled, SxProps } from '@mui/material';
import loadingIndicatorGif from './Resources/loader.gif';

interface IProps {
    sx?: SxProps;
    size?: 'default' | 'small';
    height?: string | number;
}

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const IndicatorImage = styled('img')(({ theme }) => ({
    margin: theme.spacing(3),
}));

export default function LoadingIndicator(props: IProps) {
    const { sx, size = 'default', height } = props;

    return (
        <Root sx={{ ...sx, height: height ?? ['100vh', '100dvh'] }}>
            <IndicatorImage
                sx={{ height: size === 'default' ? 96 : 60 }}
                src={loadingIndicatorGif}
                alt='loading indicator'
            />
        </Root>
    );
}
