import { Dialog, DialogProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { IDialogProps } from '../RoutingDialog';

interface IProps extends PropsWithChildren<DialogProps> {
    skipSlidingIn?: boolean;
    dialogProps?: Partial<IDialogProps>;
    fullScreen?: boolean;
}

export default function DialogBase(props: IProps) {
    const { children, fullScreen = true, skipSlidingIn, dialogProps, ...restProps } = props;

    return (
        <Dialog
            {...restProps}
            fullScreen={fullScreen}
            scroll='body'
            PaperProps={{
                sx: theme => ({
                    background: theme.palette.background.default,
                })
            }}
            sx={{
                top: dialogProps?.fitContentHeight || dialogProps?.dialogHeight ? 'unset' : 0,
                height: dialogProps?.dialogHeight
            }}
        >
            {children}
        </Dialog>
    );
}
