import { range } from 'lodash';
import { styled } from '@mui/material/styles';
import OnboardingStepsItem from './OnboardingStepsItem';

interface IProps {
    currentStep: number;
    totalSteps: number;
}

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.background.default,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3, 0),
    position: 'fixed',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
        gap: theme.spacing(2),
    }
}));

const StepsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    height: 4,
    padding: theme.spacing(0, 3),
    width: theme.breakpoints.values.lg,
}));

export default function OnboardingSteps(props: IProps) {
    const { currentStep, totalSteps } = props;

    function getIsActive(index: number) {
        return currentStep === (index + 1);
    }

    return (
        <Root>
            <StepsContainer>
                {range(totalSteps).map(s =>
                    <OnboardingStepsItem
                        key={s}
                        isActive={getIsActive(s)}
                    />
                )}
            </StepsContainer>
        </Root>
    );
}
