import { styled, SxProps } from '@mui/material';

interface IProps {
    backgroundcolor?: string;
    children: JSX.Element | JSX.Element[];
    sx?: SxProps;
}

const Root = styled('div')<IProps>(({ theme, backgroundcolor, sx }) => ({
    alignContent: 'center',
    backgroundColor: backgroundcolor,
    borderRadius: theme.spacing(2),
    display: 'grid',
    height: 328,
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    sx,
    width: '100%',

    '& img': {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    }
}));

export default function PageImageContainer(props: IProps) {
    return (
        <Root {...props} />
    );
}