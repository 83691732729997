export const pageTitles = {
    dashboard: {
        overview: 'Dashboard',
    },
    teams: {
        overview: 'Teams',
        singleTeam: (teamName: string) => `${teamName}`,
        vacations: (teamName: string) => `Vakantierooster - ${teamName}`,
    },
    practical: {
        overview: 'Praktisch',
    },
    management: {
        overview: 'Beheer',
        teams: 'Teams - Beheer',
        colleagues: "Collega's - Beheer",
        learningPaths: 'Leerpaden - Beheer',
    },
    profile: {
        overview: 'Profiel',
    },
    hoursRegistration: {
        overview: 'Uren boeken',
        edit: 'Uren bewerken - Uren boeken',
    },
    learn: {
        overview: 'Leren',
        reviewdialog: 'Reviews bekijken',
        reviewEdit: 'Review bewerken',
    },
    video: {
        overview: 'Video overzicht',
        detail: 'Video bekijken',
        create: 'Video aanmaken',
        edit: 'Video bewerken'
    },
    podcast: {
        overview: 'Podcast overzicht',
        detail: 'Podcast bekijken',
        create: 'Podcast aanmaken',
        edit: 'Podcast bewerken'
    },
    personalGoals: {
        overview: 'Persoonlijke doelen',
        detail: 'Persoonlijk doel bekijken',
        create: 'Persoonlijk doel aanmaken',
        edit: 'Persoonlijk doel bewerken',
    },
    feedback: {
        overview: 'Feedback',
        request: 'Feedbackverzoek',
        received: 'Ontvangen Feedback',
        addFeedback: 'Feedback geven',
        addFeedbackRequest: 'Feedbackverzoek aanmaken',
    }
} as const;
