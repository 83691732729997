import { Api } from '../../../ApiClients/clients';
import { IFormInputs } from '../Onboarding';
import { UpdateUser, User } from '../../../ApiClients/ApiClient';
import { IUser } from '../../../Definitions/userDefinitions';
import UsersLogic from '../../../Logic/UsersLogic';
import { IOnboardingState } from './onboardingState';
import SnackbarManager from '../../../Components/Snackbar/SnackbarManager';

export default class OnboardingLogic {
    static async fetchUserByToken(token: string): Promise<IUser> {
        const user: User = await Api.getUserByOnboardingLink(token);

        return UsersLogic.mapUserToIUser(user);
    }

    static async uploadOnboardingImage(userId: string, onboardinglink: string, file: File): Promise<string | undefined> {
        try {
            const fileName = `${userId}_onboarding_image`;
            return await Api.uploadOnboardingImage(onboardinglink, { fileName, data: file });
        } catch (error) {
            SnackbarManager.showError(error);
        }
    }

    static navigateToNextStep(prevState: IOnboardingState, data?: IFormInputs) {
        const { currentStep, totalSteps, user } = prevState;
        const nextStep = (currentStep ?? 0) + 1;

        if (nextStep - 1 === totalSteps) {
            if (user) {
                const updatedUser: UpdateUser = {
                    ...user,
                    onboardingLink: undefined,
                };

                return {
                    ...prevState,
                    user: UsersLogic.mapUserToIUser({ ...user, ...updatedUser }),
                };
            }
        }

        if (data) {
            if (user) {
                const newDate = new Date(data.birthDate);

                //This sets the time so that it doesn't get set back 1 day because of the timezone difference
                newDate.setHours(12);

                return {
                    ...prevState,
                    user: {
                        ...user,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        birthDate: newDate.toISOString(),
                        address: data.address,
                        city: data.city,
                        postalCode: data.postalCode,
                        phoneNumber: data.phoneNumber,
                        profilePicture: data.profilePicture ?? user.profilePicture,
                    },
                    currentStep: nextStep,
                };
            }
        }

        return {
            ...prevState,
            currentStep: nextStep,
        };

    }

    static navigateToPreviousStep(prevState: IOnboardingState) {
        const { user, currentStep } = prevState;
        const previousStep = (currentStep ?? 0) - 1;

        if (previousStep === 0) {
            return prevState;
        }

        const stepsWithUserManipulation = [3, 4];

        if (stepsWithUserManipulation.includes(currentStep) && user) {
            return {
                ...prevState,
                user: {
                    ...user,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    birthDate: user.birthDate,
                    address: user.address,
                    city: user.city,
                    postalCode: user.postalCode,
                    phoneNumber: user.phoneNumber,
                    profilePicture: user.profilePicture ?? undefined,
                },
                currentStep: previousStep,
            };
        }

        return {
            ...prevState,
            currentStep: previousStep,
        };
    }

    static fillDefaultValuesOnboarding(user: IUser) {
        const { address, birthDate, city, firstName, lastName, phoneNumber, postalCode } = user;

        const birthdateInit = new Date(birthDate ?? new Date()).toISOString().slice(0, 10);

        return {
            address: address ?? '',
            birthDate: birthdateInit,
            city: city ?? '',
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            phoneNumber: phoneNumber ?? '',
            postalCode: postalCode ?? '',
        };
    }
}