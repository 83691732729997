import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useTheme } from '@mui/material';
import StackComponent from '../Content/StackComponent';

interface IProps {
    open: boolean;
    onClose: () => void;
    dontSaveChanges: () => void;
}

export default function DirtyMarkerDialog(props: IProps) {
    const { open, onClose, dontSaveChanges } = props;
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='xs'>
            <Box sx={{ background: theme.palette.grey[600] }}>
                <DialogTitle sx={{ fontWeight: '600 !important', fontSize: 18 }}>Weet je het zeker?</DialogTitle>
                <DialogContent>
                    <Typography variant='body1' sx={{ marginBottom: theme.spacing(2) }}>
                        Wijzigingen die niet opgeslagen zijn zullen verloren gaan.
                    </Typography>
                    <StackComponent
                        justifyContent='flex-end'
                    >
                        <Button
                            onClick={onClose}
                            sx={{ marginRight: theme.spacing(2) }}
                            type='submit'
                        >
                            Annuleren
                        </Button>
                        <Button
                            color='error'
                            onClick={dontSaveChanges}
                            variant='contained'
                        >
                            Pagina verlaten
                        </Button>
                    </StackComponent>
                </DialogContent>
            </Box>
        </Dialog>
    );
}
