import { ArrowForward } from '@mui/icons-material';
import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemIcon, styled, Chip, Stack, SxProps, alpha } from '@mui/material';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: '#232323',
    padding: theme.spacing(1.75, 3, 1.75, 2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    cursor: 'pointer',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius * 5,
    border: `1px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[300],
    backgroundColor: alpha(theme.palette.grey[600], .5),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 13,

    [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        padding: theme.spacing(.3)
    }
}));

interface IProps {
    avatarSrc?: string;
    avatarContent?: string;
    onClick: () => void;
    primary: string;
    secondary?: string;
    chipLabel?: string;
    sx?: SxProps
}

export default function ClickableListItem(props: IProps) {
    const { avatarSrc, avatarContent, onClick, primary, secondary, chipLabel, sx } = props;

    return (
        <StyledListItem onClick={onClick} sx={sx}>
            {(avatarSrc || avatarContent) &&
                <ListItemAvatar>
                    <Avatar sx={{ borderRadius: 2}} src={avatarSrc} alt={avatarContent}>
                        {avatarContent}
                    </Avatar>
                </ListItemAvatar>
            }

            <ListItemText
                sx={{ m: 0 }}
                primary={primary}
                secondary={secondary}
                primaryTypographyProps={{
                    sx: { fontWeight: 500, color: 'text.primary' }
                }}
                secondaryTypographyProps={{
                    sx: { color: 'grey.200'}
                }}
            />

            <Stack direction={{xs: 'column', sm: 'row'}} alignItems={{xs: 'end', sm: 'center'}} gap={2}>
                {chipLabel &&
                    <StyledChip label={chipLabel}/>
                }

                <ListItemIcon sx={{ minWidth: 'unset' }}>
                    <ArrowForward sx={{ color: 'white', fontSize: 21 }} />
                </ListItemIcon>
            </Stack>
        </StyledListItem>
    );
}
