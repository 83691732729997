import { dialogClasses, DialogProps, Slide, SlideProps, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, PropsWithChildren } from 'react';
import DialogBase from './DialogBase';

export interface IDialogProps extends DialogProps {
    skipSlidingIn?: boolean;

    // TODO: better implementation of this.
    dialogProps?: Partial<{ offsetTop: number }>
}

const StyledDialogBase = styled(DialogBase)(() => ({
    [`& .${dialogClasses.container}`]: {
        height: 'unset',
    },

    [`& .${dialogClasses.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        height: ['100vh', '100dvh'],
    }
}));

// The transition should be defined outside of the component, otherwise the backdrop will remain mounted.
const SlideLeftTransition = forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide direction='left' ref={ref} {...props as PropsWithChildren<TransitionProps> & Pick<SlideProps, 'children'>} />;
});

export default function SlideLeftDialog(props: IDialogProps) {
    const { skipSlidingIn, dialogProps, ...restProps } = props;

    return <StyledDialogBase
        {...restProps}
        TransitionComponent={!skipSlidingIn ? SlideLeftTransition : undefined}
        transitionDuration={skipSlidingIn ? 0 : undefined}
        sx={{ top: dialogProps?.offsetTop }}
    />;
}
