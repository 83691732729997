import { Clear as ClearIcon, Search } from '@mui/icons-material';
import { IconButton, SxProps, styled } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { StyledSearchInput } from '../StyledComponents';
import _ from 'lodash';

export interface ISearchFieldProps {
    value?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    sx?: SxProps;
    disabled?: boolean;
    autoFocus?: boolean;
    debounce?: number;
}

const SearchIcon = styled(Search)(({ theme }) => ({
    opacity: .5,
    color: theme.palette.text.primary,
    margin: theme.spacing(1, 1.5),
    width: 24,
}));

export default function ColoredDialogSearchInput(props: ISearchFieldProps) {
    const { value, onChange, placeholder = 'Zoeken', sx, disabled, autoFocus, debounce = 0 } = props;

    const debounceHandler = useCallback(
        _.debounce(onChange, debounce),
        [onChange]);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        if (debounce === 0) {
            onChange(e.target.value);
        } else {
            debounceHandler.cancel();
            debounceHandler(e.target.value);
        }

    }

    function handleClear() {
        onChange('');
    }

    return (
        <StyledSearchInput
            sx={sx}
            onChange={handleChange}
            placeholder={placeholder}
            type='text'
            value={value}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => e.stopPropagation()}
            startAdornment={
                <SearchIcon />
            }
            endAdornment={
                value &&
                <IconButton color='secondary' onClick={handleClear}>
                    <ClearIcon fontSize='small' />
                </IconButton>
            }
        />
    );
}
