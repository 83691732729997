type ErrorMessageType =
    'required' |
    'minLength' |
    'maxLength' |
    'pattern' |
    'unique' |
    'exists' |
    'number' |
    'hours' |
    'min' |
    'max' |
    'decimals' |
    'valid';

export interface IErrorMessages {
    [key: string]: string;
}

export const errorMessages: IErrorMessages = {
    max: 'De ingevoerde waarde kan niet groter zijn dan {{max}}.',
    maxLength: 'De ingevoerde tekst mag maximaal {{maxLength}} tekens bevatten.',
    min: 'De ingevoerde waarde moet groter zijn dan {{min}}.',
    minLength: 'De ingevoerde tekst moet ten minste {{minLength}} tekens bevatten.',
    pattern: 'De ingevoerde tekst bevat niet de correcte tekens, er wordt een waarde verwacht als {{pattern}}.',
    required: 'Dit veld is verplicht.',
    unique: 'Deze waarde is niet uniek.',
    exists: 'De ingevoerde waarde bestaat al.',
    number: 'De ingevoerde waarde moet een nummer zijn.',
    hours: 'De ingevoerde waarde moet tussen de 0-24 zijn.',
    decimals: 'De ingevoerde waarde moet minimaal 1 cijfer voor de komma en mag maximaal 2 cijfers achter de komma.',
    valid: 'De ingevoerde {{valid}} is niet geldig.'
};

export default class ErrorMessagesLogic {
    static getErrorMessageByType(messageType: ErrorMessageType, textToReplace?: string | number) {
        return errorMessages[messageType].replace(`{{${messageType}}}`, String(textToReplace));
    }
}