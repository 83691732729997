import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePersistedIdFromUrl(baseUrl: string): string | undefined {
    const regex = new RegExp(`(\\/${baseUrl}\\/)(?<id>[\\w|-]+)`);
    const location = useLocation();

    const groups = location.pathname.match(regex)?.groups;
    const idRef = useRef(groups?.id);

    return idRef.current;
}