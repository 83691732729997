import { styled, Typography } from '@mui/material';
import { Logo, TextWithColor } from '../../../../Components';
import theme from '../../../../Theme';

const Root = styled('div')({
    alignItems: 'center',
    display: 'flex',
    height: ['100vh', '100dvh'],
    justifyContent: 'center',
    overflow: 'hidden',
});

const BubbleContainer = styled('div')(({ theme }) => ({
    height: ['100vh', '100dvh'],
    position: 'absolute',
    width: 720,

    [theme.breakpoints.down('md')]: {
        width: '100%',
        overflow: 'hidden',
    }
}));

interface IBubbleProps {
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
    size: number | string;
    top?: number | string;
}

const Bubble = styled('div')<IBubbleProps>(({ size, top, left, bottom, right }) => ({
    animation: 'bubble 3s ease-in-out',
    background: 'rgba(255, 255, 255, .05)',
    borderRadius: 9999,
    bottom,
    height: size,
    left,
    position: 'absolute',
    right,
    top,
    transform: 'translate(0, -100%)',
    width: size,

    '@keyframes bubble': {
        'from': {
            transform: 'translate(0, 0)'
        },
        'to': {
            transform: 'translate(0, -100%)'
        }
    }
}));

const LoadingPageContent = styled('div')({
    display: 'grid',
    justifyItems: 'center',
    width: '180px',
    zIndex: 9,
});

export default function OnboardingLoadingScreen() {
    return (
        <Root>
            <BubbleContainer>
                <Bubble size={280} top='25%' right={-40} />
                <Bubble size={160} bottom='15%' left={0} />
                <Bubble size={124} bottom={'5%'} right={0} />
            </BubbleContainer>

            <LoadingPageContent>
                <Logo sx={{ fontSize: 72, marginBottom: 4 }} />
                <Typography variant='h1' sx={{ textAlign: 'center' }}>loading <TextWithColor color={theme.palette.primary.main}>your</TextWithColor> new job</Typography>
            </LoadingPageContent>
        </Root>
    );
}