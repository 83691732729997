import { atom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User } from '../../../ApiClients/ApiClient';
import SnackbarManager from '../../../Components/Snackbar/SnackbarManager';
import { IUser } from '../../../Definitions/userDefinitions';
import UsersLogic from '../../../Logic/UsersLogic';
import { IFormInputs } from '../Onboarding';
import { OnboardingLogic } from '../Scenes';

export interface IOnboardingState {
    currentStep: number;
    totalSteps: number;
    user: IUser;
    uploadedPhoto: File | null;
}

const defaultState: IOnboardingState = {
    currentStep: 1,
    totalSteps: 1,
    user: UsersLogic.getEmptyUser(),
    uploadedPhoto: null,
};

export const onboardingStateAtom = atom<IOnboardingState>(defaultState);

export function useOnboardingState(initializeOnMount?: boolean, totalSteps?: number) {
    const setState = useSetAtom(onboardingStateAtom);

    const [isLoading, setIsLoading] = useState(false);

    const { token: tokenFromParams } = useParams();

    useEffect(() => {
        if (initializeOnMount && tokenFromParams) {
            void initialize(tokenFromParams);
        }
    }, [tokenFromParams]);

    async function initialize(token: string) {
        setIsLoading(true);

        try {
            const user: User = await OnboardingLogic.fetchUserByToken(token);
            const mappedUser: IUser = UsersLogic.mapUserToIUser(user);

            setState(prevState => ({ ...prevState, user: mappedUser, totalSteps: totalSteps ?? 1 }));
        } catch (error) {
            SnackbarManager.showError(error);
        } finally {
            setIsLoading(false);
        }
    }

    function navigateToNextStep(formData?: IFormInputs) {
        setState(prevState => OnboardingLogic.navigateToNextStep(prevState, formData));
    }

    function navigateToPreviousStep() {
        setState(prevState => OnboardingLogic.navigateToPreviousStep(prevState));
    }

    return {
        isLoading,
        navigateToNextStep,
        navigateToPreviousStep,
        resetOnboardingState: () => setState(defaultState),
        setOnboardingState: setState,
    };
}