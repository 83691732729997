import { styled } from '@mui/material/styles';

interface IProps {
    isActive: boolean,
};

const Root = styled('div')<IProps>(({ theme, isActive }) => ({
    background: isActive ? theme.palette.primary.main : theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    height: '100%',
}));

export default function OnboardingStepsItem(props: IProps) {
    return (
        <Root data-testid='onboarding-step-item' {...props} />
    );
}
