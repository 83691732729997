export default class RegexHelper {
    static getEmailRegex(): RegExp {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    static getPostalCodeRegex(): RegExp {
        return /^[1-9][0-9]{3}\s?([a-zA-Z]{2})$/;
    }

    static getOnlyNumberAndDecimals(): RegExp {
        return /^((?:[0-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/;
    }

    static getUrlRegex(): RegExp {
        return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
    }

    // This checks if there is text inserted inside an html element..
    static getHtmlElementWithTextRegex(): RegExp {
        return /^(?!<([a-z]+)(?:\s[^>]*)?>(?:\s*<[^>]*>)*\s*\S[^<]*<\/\1>$).*$/;
    }
}