import { Box, ButtonBase, Chip, styled, SxProps, Typography, useTheme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface IProps {
    additionalSubtitle?: string;
    isNew?: boolean;
    sx?: SxProps;
    onClick?: () => void;
    text: string;
}

const Root = styled(ButtonBase)(({ theme }) => ({
    background: theme.palette.grey[500],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    width: '100%',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.text.primary,
    height: 'auto',
    marginRight: theme.spacing(1),
}));

/** @deprecated use ClickableListItem instead */
export default function ClickableContainer(props: IProps) {
    const { additionalSubtitle, text, isNew, onClick, sx } = props;
    const theme = useTheme();

    return (
        <Root sx={sx} onClick={onClick}>
            <Typography variant='h5' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', lineHeight: 1.5 }}>{text}<br />
                <Typography variant='body2' sx={{ lineHeight: 1 }}>{additionalSubtitle}</Typography>
            </Typography>

            <Box sx={{ display: 'inline-flex' }}>
                {isNew &&
                    <StyledChip label='Nieuw' color='primary' />
                }

                <ArrowForwardIosIcon sx={{ color: theme.palette.grey[700] }} />
            </Box>
        </Root>
    );
}
