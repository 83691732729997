import { useTheme } from '@mui/material';
import { useState } from 'react';

type UseDialogOpenType = [boolean, (open: boolean) => void, boolean];

export default function useDialogOpen(open: boolean = false, afterShouldRenderDialogCallback?: () => void): UseDialogOpenType {
    const [dialogOpen, setDialogOpen] = useState(open);
    const [shouldRenderDialog, setShouldRenderDialog] = useState(open);

    const theme = useTheme();
    const delay = theme.transitions.duration.leavingScreen ?? 500;

    function handleChangeDialogOpen(open: boolean) {
        setDialogOpen(open);

        if (open) {
            setShouldRenderDialog(open);
        } else {
            setTimeout(() => {
                setShouldRenderDialog(open);
                afterShouldRenderDialogCallback?.();
            }, delay);
        }
    }

    return [
        dialogOpen,
        handleChangeDialogOpen,
        shouldRenderDialog,
    ];
}