import { FormHelperText, FormHelperTextProps } from '@mui/material';

export default function FormGroupErrorText(props: FormHelperTextProps) {
    const { children, error } = props;

    if (!children && !error) {
        return null;
    }

    return (
        <FormHelperText error>
            {children}
        </FormHelperText>
    );
}
