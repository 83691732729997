import { Avatar, styled, Typography, Box, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { OnboardingNavigateNextButton } from '../Components';
import { PageImageContainer } from '../../../../Components';
import { usePageTitle } from '../../../../Hooks';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom } from '../../Logic/onboardingState';

const MiddleContainer = styled('div')({
    textAlign: 'center',
});

const StyledLink = styled(Link)({
    textDecoration: 'none',
});

export default function OnboardingBuddyInfoPage() {
    const { user } = useAtomValue(onboardingStateAtom);

    const { buddyFullName } = user;

    const theme = useTheme();

    usePageTitle('Je buddy - Onboarding');

    return (
        <>
            <PageImageContainer backgroundcolor={theme.palette.primary.main}>
                <MiddleContainer>
                    <Avatar
                        alt={buddyFullName}
                        src={user.buddyProfilePicture}
                        sx={{ display: 'inline-flex', width: 114, height: 114, borderRadius: theme.shape.borderRadius, }}
                    />
                    <Typography sx={{ color: theme.palette.grey[800], margin: theme.spacing(2, 0, .5) }} variant='h2'>{buddyFullName}</Typography>
                    <Typography sx={{ color: theme.palette.grey[800], opacity: '.7' }} variant='body1'>{user.buddyJobTitle}</Typography>
                </MiddleContainer>
            </PageImageContainer>

            <Box my={3}>
                <Typography variant='h2'>Dit is je buddy!</Typography>
                <Typography sx={{ margin: theme.spacing(1, 0) }} variant='body1'>Je buddy begeleidt je tijdens het inwerkprogramma en is je go-to person voor vragen en antwoorden.</Typography>
            </Box>

            <Typography sx={{ margin: theme.spacing(1, 0) }} variant='body1'>Even voorstellen</Typography>
            <Typography variant='body1'>{user.buddyBio ?? "Het ziet er naar uit dat je buddy geen hobby's heeft"}</Typography>

            <StyledLink to='/login'>
                <OnboardingNavigateNextButton />
            </StyledLink>
        </>
    );
}