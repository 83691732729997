import devConfig from './dev';
import prodConfig from './prod';
import stagingConfig from './staging';

export interface IConfig {
    apiUrl: string;
    googleApiKey: string;
    slackWebhookURL: string;
    unsplashAccessKey: string;
}

type EnvironmentTypes = 'dev' | 'staging' | 'live';

export function getEnvironment(): EnvironmentTypes {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
        return 'dev';
    } else if (hostname === 'annie-portal-staging.azurewebsites.net') {
        return 'staging';
    } else {
        return 'live';
    }
}

function getConfiguration(environment: EnvironmentTypes): IConfig {
    switch (environment) {
        case 'dev':
            return devConfig;
        case 'staging':
            return stagingConfig;
        default:
            return prodConfig;
    }
}

const environment = getEnvironment();
export default getConfiguration(environment);
