import { createTheme } from '@mui/material';
import { overrideMuiPaper } from './Components/paper';
import { overrideMuiAlert } from './Components/alert';
import { overrideMuiAvatar } from './Components/avatar';
import { overrideMuiButton, overrideMuiIconButton } from './Components/buttons';
import { overrideMuiChip } from './Components/chip';
import { overrideMuiContainer } from './Components/container';
import { overrideMuiDivider } from './Components/divider';
import { overrideMuiLinearProgress } from './Components/linearprogress';
import { overrideMuiMenu } from './Components/menulist';
import { overrideMuiTableContainer, overrideMuiTablePagination } from './Components/table';
import { overrideMuiTextField } from './Components/textfield';
import { overrideMuiTooltip } from './Components/tooltip';
import overrideShadows from './Styles/shadow';
import palette from './Styles/palette';
import typography from './Styles/typography';
import { overrideMuiAccordion } from './Components/accordion';

const theme = createTheme({
    palette,
    typography,
    shape: {
        // borderRadiusStandard: 8,
    }
});

theme.components = {
    MuiAccordion: overrideMuiAccordion(theme),
    MuiAvatar: overrideMuiAvatar(theme),
    MuiButton: overrideMuiButton(theme),
    MuiChip: overrideMuiChip(theme),
    MuiIconButton: overrideMuiIconButton(),
    MuiMenu: overrideMuiMenu(theme),
    MuiPaper: overrideMuiPaper(theme),
    MuiTextField: overrideMuiTextField(theme),
    MuiTooltip: overrideMuiTooltip(theme),
    MuiAlert: overrideMuiAlert(theme),
    MuiLinearProgress: overrideMuiLinearProgress(theme),
    MuiContainer: overrideMuiContainer(theme),
    MuiDivider: overrideMuiDivider(theme),
    MuiTableContainer: overrideMuiTableContainer(theme),
    MuiTablePagination: overrideMuiTablePagination(theme),
};

theme.shadows = overrideShadows(theme);

export default theme;