export default class ObjectHelper {
    static isEmpty(obj: Object) {
        return (
            obj &&
            Object.keys(obj).length === 0 &&
            obj.constructor === Object
        );
    }

    static isNotEmpty<TValue>(value: TValue | null | undefined): value is TValue {
        return value !== null && value !== undefined;
    }
}