import { TypographyOptions } from '@mui/material/styles/createTypography';

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 500;

const typography: TypographyOptions = {
    fontWeightLight: fontWeightLight,
    fontWeightRegular: fontWeightRegular,
    fontWeightMedium: fontWeightMedium,
    allVariants: {
        fontFamily: "'Readex Pro', sans-serif",
    },
    h1: {
        fontSize: 48,
        fontWeight: fontWeightMedium,
        color: '#fff',
    },
    h2: {
        fontSize: 24,
        fontWeight: fontWeightMedium,
        color: '#fff',
    },
    h3: {
        fontSize: 17,
        fontWeight: fontWeightMedium,
        color: '#fff',
    },
    h4: {
        fontSize: 16,
        fontWeight: fontWeightMedium,
        color: '#fff'
    },
    h5: {
        fontSize: 16,
        fontWeight: fontWeightMedium,
        color: '#fff',
    },
    h6: {
        fontSize: 15,
        fontWeight: fontWeightMedium,
        color: '#fff',
    },
    body1: {
        fontSize: 15,
        fontWeight: fontWeightLight,
        color: '#98979C',
    },
    body2: {
        color: '#707070',
        fontSize: 15,
        fontWeight: fontWeightLight,
    },
    subtitle1: {
        color: '#707070',
        fontSize: 15,
        fontWeight: fontWeightLight,
    },
    subtitle2: {
        opacity: .5,
        fontSize: 12,
        fontWeight: fontWeightLight,
    },
};

export default typography;