import { Button, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../Theme';

const ErrorScreen = styled('div')(({ theme }) => ({
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.values.sm,
}));

export default function ErrorContainer() {
    const navigate = useNavigate();

    const toLogin = () => {
        navigate('/login');
    };

    return (
        <ErrorScreen>
            <Typography variant='h2' sx={{ marginBottom: theme.spacing(2) }}>Uitnodiging niet gevonden</Typography>
            <Typography variant='body1' sx={{ marginBottom: theme.spacing(4) }}>De uitnodiging kan niet gevonden worden. Neem contact op met de helpdesk of druk op de onderstaande knop om naar de hoofdpagina te gaan.</Typography>
            <Button variant='contained' onClick={toLogin}>Terug naar hoofdpagina</Button>
        </ErrorScreen>
    );
}