import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorBoundary, LoadingIndicator } from './Components';
import { getSpecialTheme } from './Helpers/MainHelper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackbarSingleton } from './Components/Snackbar';
import { Suspense, useContext } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { withContext } from './Decorators';
import createRoutes from './Routes';
import GlobalQueryClientProvider from './GlobalQueryClientProvider';
import MainContextProvider, { MainContext } from './Modules/Main/MainContext';

function App() {
    const { loggedInUser, hasFeedbackEarlyAccess } = useContext(MainContext);

    const routes = createRoutes(loggedInUser.isDev, hasFeedbackEarlyAccess);
    const router = createBrowserRouter(routes);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <GlobalQueryClientProvider>
            <ErrorBoundary>
                {loggedInUser &&
                    getSpecialTheme(isSmallScreen, loggedInUser.birthDate)
                }
                <SnackbarSingleton />
                <LocalizationProvider adapterLocale='nl' dateAdapter={AdapterDayjs}>
                    <Suspense fallback={<LoadingIndicator />}>
                        <RouterProvider router={router} />
                    </Suspense>
                </LocalizationProvider>
            </ErrorBoundary>
        </GlobalQueryClientProvider>
    );
}

export default withContext(MainContextProvider, App);
