import { DateFormGroup, DefaultFormGroup } from '../../../../Components';
import { Grid, Typography } from '@mui/material';
import { IFormInputs } from '../../Onboarding';
import { OnboardingLogic } from '..';
import { OnboardingNavigateNextButton } from '../Components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { usePageTitle } from '../../../../Hooks';
import ErrorMessagesLogic from '../../../../Components/Form/Logic/errorMessagesLogic';
import theme from '../../../../Theme';
import { useAtomValue } from 'jotai';
import { onboardingStateAtom, useOnboardingState } from '../../Logic/onboardingState';

interface IProps {
    onUpdateDirty: (isDirty: boolean) => void;
}

export default function OnboardingPersonalInfoPage(props: IProps) {
    const { user } = useAtomValue(onboardingStateAtom);
    const { navigateToNextStep } = useOnboardingState();

    usePageTitle('Vertel iets over jezelf - Onboarding');

    const { control, formState, handleSubmit } = useForm<IFormInputs>({
        defaultValues: OnboardingLogic.fillDefaultValuesOnboarding(user),
        mode: 'onChange',
    });

    useEffect(() => {
        props.onUpdateDirty(formState.isDirty);
    }, [formState.isDirty]);

    function onSubmit(data: IFormInputs) {
        navigateToNextStep(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant='h2'>Iets meer over jou</Typography>
            <Typography sx={{ margin: theme.spacing(1, 0, 2) }} variant='body1'>Stel jezelf voor!</Typography>

            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                    <DefaultFormGroup<IFormInputs>
                        control={control}
                        fullWidth
                        label='Hoe mogen we je noemen?'
                        name='firstName'
                        placeholder='Voornaam'
                        rules={{
                            required: ErrorMessagesLogic.getErrorMessageByType('required'),
                            maxLength: { value: 40, message: ErrorMessagesLogic.getErrorMessageByType('maxLength', 40) }
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <DefaultFormGroup<IFormInputs>
                        control={control}
                        fullWidth
                        label='Wat is je achternaam?'
                        name='lastName'
                        placeholder='Achternaam'
                        rules={{
                            required: ErrorMessagesLogic.getErrorMessageByType('required'),
                            maxLength: { value: 40, message: ErrorMessagesLogic.getErrorMessageByType('maxLength', 40) }
                        }}
                    />
                </Grid>
            </Grid>

            <DateFormGroup<IFormInputs>
                control={control}
                fullWidth
                label='Wanneer ben je geboren?'
                name='birthDate'
                placeholder='Geboortedatum'
                rules={{
                    required: ErrorMessagesLogic.getErrorMessageByType('required'),
                    minLength: { value: 8, message: ErrorMessagesLogic.getErrorMessageByType('minLength', 8) },
                    maxLength: { value: 12, message: ErrorMessagesLogic.getErrorMessageByType('minLength', 12) }
                }}
                sx={{ marginBottom: 2 }}
            />

            <DefaultFormGroup<IFormInputs>
                control={control}
                fullWidth
                label='Waar woon je?'
                name='address'
                placeholder='Adres'
                rules={{
                    required: ErrorMessagesLogic.getErrorMessageByType('required'),
                    minLength: { value: 3, message: ErrorMessagesLogic.getErrorMessageByType('minLength', 3) },
                    maxLength: { value: 40, message: ErrorMessagesLogic.getErrorMessageByType('maxLength', 40) }
                }}
                sx={{ marginBottom: 2 }}
            />

            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                    <DefaultFormGroup<IFormInputs>
                        control={control}
                        fullWidth
                        name='city'
                        placeholder='Woonplaats'
                        rules={{
                            required: ErrorMessagesLogic.getErrorMessageByType('required'),
                            minLength: { value: 3, message: ErrorMessagesLogic.getErrorMessageByType('minLength', 3) },
                            maxLength: { value: 40, message: ErrorMessagesLogic.getErrorMessageByType('maxLength', 40) }
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DefaultFormGroup<IFormInputs>
                        control={control}
                        fullWidth
                        name='postalCode'
                        placeholder='Postcode'
                        rules={{
                            required: ErrorMessagesLogic.getErrorMessageByType('required'),
                            minLength: { value: 4, message: ErrorMessagesLogic.getErrorMessageByType('minLength', 3) },
                            maxLength: { value: 8, message: ErrorMessagesLogic.getErrorMessageByType('maxLength', 8) }
                        }}
                    />
                </Grid>
            </Grid>

            <DefaultFormGroup<IFormInputs>
                control={control}
                fullWidth
                label='Wat is je telefoonnummer?'
                name='phoneNumber'
                placeholder='06 -'
                rules={{
                    required: ErrorMessagesLogic.getErrorMessageByType('required'),
                    minLength: { value: 8, message: ErrorMessagesLogic.getErrorMessageByType('minLength', 8) },
                    maxLength: { value: 14, message: ErrorMessagesLogic.getErrorMessageByType('maxLength', 14) }
                }}
                sx={{ marginBottom: 2 }}
            />

            <OnboardingNavigateNextButton type='submit' />
        </form>
    );
}