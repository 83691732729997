import { Box } from '@mui/material';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { FormGroupErrorText, FormGroupLabel } from '../..';
import { IFormGroupProps } from '../Definitions/formGroupDefinitions';
import UploadImage, { IUploadImageProps } from './UploadImage';
import UploadImageEmptyState from '../../EmptyStates/UploadImageEmptyState';

interface IProps<T extends FieldValues> extends IFormGroupProps<T> {
    imageUploadProps?: Partial<Pick<IUploadImageProps, 'wrapperSx' | 'emptyImageState' | 'detailImageSx'>>;
    onChangeCallback: (imageToUpload: File | null) => void;
}

export default function ImageFormGroup<T extends FieldValues>(props: IProps<T>) {
    const { control, imageUploadProps, label, name, onChangeCallback, rules } = props;

    type renderPropsType = Parameters<ControllerProps<T>['render']>[0];

    function renderInput(props: renderPropsType) {
        const { field: { onChange, value }, fieldState: { error, invalid } } = props;

        const handleChange = (thumbnailBlobUrl: string, imageToUpload: File | null) => {
            onChange(thumbnailBlobUrl);
            onChangeCallback(imageToUpload);
        };

        return (
            <Box>
                <FormGroupLabel
                    rules={rules}
                    sx={{ color: 'text.primary', opacity: .5, mb: 1 }}
                >
                    {label}
                </FormGroupLabel>

                <UploadImage
                    {...imageUploadProps}
                    emptyImageState={imageUploadProps?.emptyImageState ?? <UploadImageEmptyState />}
                    value={value}
                    onChange={handleChange}
                    invalid={invalid}
                />

                <FormGroupErrorText error={invalid}>
                    {error?.message ?? ''}
                </FormGroupErrorText>
            </Box>
        );
    }

    return (
        <Controller
            control={control}
            name={name}
            render={renderInput}
            rules={rules}
        />
    );
}
