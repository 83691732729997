import { Avatar, AvatarGroup, Stack, SxProps, Tooltip, avatarClasses, avatarGroupClasses, styled } from '@mui/material';
import { MainContext } from '../../Modules/Main/MainContext';
import { useContext } from 'react';
import { UsersLogic } from '../../Logic';
import { UserSummary } from '../../ApiClients/ApiClient';
import { defaultSizeMapping } from '../../Theme/Styles/sizes';

const StyledAvatarGroup = styled(AvatarGroup, { shouldForwardProp: p => p !== 'avatarSize' })<{ avatarSize: number }>(({ theme, avatarSize }) => ({
    [`.${avatarGroupClasses.avatar}`]: {
        borderRadius: '50%',
    },

    [`& .${avatarGroupClasses.avatar}`]: {
        [theme.breakpoints.down('sm')]: {
            width: 30,
            height: 30,
            fontSize: 14,
        }
    },

    [`.${avatarClasses.root}.${avatarClasses.circular}.${avatarClasses.colorDefault}.${avatarGroupClasses.avatar}`]: {
        width: avatarSize,
        Height: avatarSize,
        marginLeft: '-9px !important',
        fontSize: 18,
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.primary.main,
        border: '2px solid transparent',

        [theme.breakpoints.down('sm')]: {
            height: 30,
            width: 30,
            marginLeft: '-10px !important',
            fontSize: 14,
        }
    }
}));

interface IProps {
    users?: UserSummary[];
    size?: 'small' | 'medium' | 'large'
    maxShown?: number;
    sx?: SxProps;
}

export default function UserAvatarsGroup(props: IProps) {
    const { users, size = 'large', maxShown = 5, sx } = props;
    const avatarSize = defaultSizeMapping[size];

    const { loggedInUser } = useContext(MainContext);

    if (!(users && users.length > 0)) {
        return null;
    }

    const sortedUsers = users.sort((user) => {
        if (user.id === loggedInUser.id) return -1;
        return 0;
    });

    return (
        <Stack direction='row' alignItems='center' sx={sx}>
            <StyledAvatarGroup
                spacing='small'
                max={maxShown}
                total={users.length}
                avatarSize={avatarSize}
            >
                {sortedUsers.map(u => (
                    <Tooltip key={u.id} title={UsersLogic.getFullName(u)} arrow>
                        <Avatar key={u.id} src={u.profilePicture} sx={{ width: avatarSize, height: avatarSize }} />
                    </Tooltip>
                ))}
            </StyledAvatarGroup>
        </Stack>
    );
}
