import { Breakpoint, Container, styled, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IProps {
    sx?: SxProps;
    maxWidth?: Breakpoint | false;
}

const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

export default function Content(props: PropsWithChildren<IProps>) {
    const { sx, children, maxWidth = 'xl' } = props;

    return (
        <StyledContainer sx={sx} maxWidth={maxWidth}>
            {children}
        </StyledContainer>
    );
}
