import { useMemo, useState } from 'react';
import Fuse, { FuseOptionKey, IFuseOptions } from 'fuse.js';

interface IProps<T> {
    data: T[];
    keys: FuseOptionKey<T>[];
}

const scoreThreshold = 0.4;

export default function useFuzzySearch<T>(props: IProps<T>) {
    const { data, keys } = props;

    // TODO: rename to query
    const [searchValue, setSearchValue] = useState('');

    const fuse = useMemo(() => {
        const options: IFuseOptions<T> = {
            keys,
            threshold: scoreThreshold,
            shouldSort: true,
            distance: 800
        };

        return new Fuse(data, options);
    }, [data, keys]);

    const results = useMemo(() => {
        if (!searchValue) return data;

        return fuse.search(searchValue).map((fuseResult) => fuseResult.item);
    }, [fuse, searchValue, data]);

    return {
        results,
        searchValue,
        setSearchValue
    };
}