import { createContext, PropsWithChildren, SetStateAction, useMemo, useState } from 'react';
import { IUser } from '../../Definitions/userDefinitions';
import { RoleType } from '../../ApiClients/ApiClient';
import UsersLogic from '../../Logic/UsersLogic';

type earlyAccessFeaturesType = 'feedback';
const earlyAccessFeatures = new Map<earlyAccessFeaturesType, string[]>([
    ['feedback', [
        'vanja@jem-id.nl',
        'bas@jem-id.nl',
        'bramvermeer@jem-id.nl',
        'jacco@jem-id.nl',
        'youri@jem-id.nl',
    ]]
]);

export interface IMainContextState {
    loggedInUser: IUser;
    updateMainState: (newState: SetStateType) => void;
    isAdmin: boolean;
    isContentCreator: boolean;
    hasFeedbackEarlyAccess: boolean;
}

type SetStateType = SetStateAction<Partial<IMainContextState>>;

const defaultState: IMainContextState = {
    loggedInUser: UsersLogic.getEmptyUser(),
    updateMainState: () => { throw new TypeError('Invalid MainContext.'); },
    // TODO: place this inside IUser
    isAdmin: false,
    // TODO: place this inside IUser
    isContentCreator: false,
    // TODO: remove this early access flag
    hasFeedbackEarlyAccess: false,
};

export const MainContext = createContext<IMainContextState>(defaultState);

export default function MainContextProvider(props: PropsWithChildren<{}>) {
    const [state, setState] = useState<IMainContextState>({
        ...defaultState,
        updateMainState,
    });

    function updateMainState(newState: SetStateType) {
        setState((prevState: IMainContextState): IMainContextState => ({
            ...prevState,
            ...(typeof newState === 'function'
                ? newState(prevState)
                : newState
            ),
        }));
    };

    const isAdmin: boolean = useMemo(() => state.loggedInUser?.role === RoleType.Admin ?? false, [state.loggedInUser]);
    const isContentCreator: boolean = useMemo(() => state.loggedInUser?.role === RoleType.ContentCreator ?? false, [state.loggedInUser]);
    const hasFeedbackEarlyAccess: boolean = useMemo(() => earlyAccessFeatures.get('feedback')?.includes(state.loggedInUser?.email) || state.loggedInUser.isDev, [state.loggedInUser]);

    return (
        <MainContext.Provider value={{ ...state, isAdmin, isContentCreator, hasFeedbackEarlyAccess }}>
            {props.children}
        </MainContext.Provider>
    );
}