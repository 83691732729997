import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePageTitle(title: string): void {
    const location = useLocation();

    const setPageTitle = (title?: string) => {
        const mainTitle = 'Annie App';

        if (title) {
            document.title = `${title} - ${mainTitle}`;
        } else {
            document.title = mainTitle;
        }
    };

    useEffect(() => {
        setPageTitle(title);
    }, [title, location]);
}
