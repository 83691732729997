import { styled } from '@mui/material';
import FormGroupLabel from './FormGroupLabel';
import { FieldValues, Path, RegisterOptions } from 'react-hook-form';

interface IProps<T extends FieldValues> {
    rules: Omit<RegisterOptions<T, Path<T>>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> | undefined
    maxCharacters?: number;
    value?: string;
    label?: string;
    isLight?: boolean;
    tooltipText?: string;
}

const FormGroupHelperContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
        gap: theme.spacing(20)
    }
}));

const CharacterCountWrapper = styled('div', { shouldForwardProp: p => p !== 'isLight' })<Partial<{ isLight: boolean }>>(({ theme, isLight }) => ({
    color: isLight ? theme.palette.text.secondary : theme.palette.grey[100],
    opacity: isLight ? 1 : .5,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight,
    marginTop: theme.spacing(.4),
    width: 48
}));

export default function FormGroupCharacterCounter<T extends FieldValues>(props: IProps<T>) {
    const { label, maxCharacters, value, isLight, rules, tooltipText } = props;

    function countCharacters(value: string) {
        return value?.length;
    }

    return (
        <FormGroupHelperContainer>
            <FormGroupLabel
                isLight={isLight}
                tooltipText={tooltipText}
                rules={rules}
            >
                {label ?? ''}
            </FormGroupLabel>

            {maxCharacters &&
                <CharacterCountWrapper isLight={isLight}>
                    {countCharacters(value ?? '') ?? 0}/{maxCharacters}
                </CharacterCountWrapper>
            }
        </FormGroupHelperContainer>
    );
}
