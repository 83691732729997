import { AlertColor } from '@mui/material';

interface IError {
    title: string;
    status: number;
}
export interface ISnackbarData {
    title?: string;
    error?: IError;
    severity: AlertColor;
}

export default class SnackbarManager {
    private static snackbarInstance: any;
    private static snackbars: ISnackbarData[] = [];

    public static setSnackbarInstance(defaultSnackbar: any) {
        SnackbarManager.snackbarInstance = defaultSnackbar;
    }

    public static show(title: string) {
        SnackbarManager.placeNewSnackbar({ title, severity: 'success' });
    }

    public static showError(error: unknown | string) {
        if (typeof error === 'string') {
            SnackbarManager.placeNewSnackbar({ title: error, severity: 'error' });
            return;
        }

        if (error && typeof error === 'object' && 'response' in error) {
            const response = JSON.parse(error.response as string);
            if ('title' in response) {
                SnackbarManager.placeNewSnackbar({ error: response as IError, severity: 'error' });
            }
        }
    }

    public static showWarning(title: string) {
        SnackbarManager.placeNewSnackbar({ title, severity: 'warning' });
    }

    public static showInfo(title: string) {
        SnackbarManager.placeNewSnackbar({ title, severity: 'info' });
    }

    public static getNextSnackbarData(): ISnackbarData | undefined {
        return SnackbarManager.snackbars.shift();
    }

    private static placeNewSnackbar(snackbarData: ISnackbarData) {
        if (!SnackbarManager.snackbarInstance) {
            throw new Error('Error: No "SnackbarSingleton" could be found. Place the "SnackbarSingleton" component in the "render()" of the root component');
        }

        SnackbarManager.snackbarInstance.closeSnackbar();
        const placed = SnackbarManager.snackbarInstance.placeNewSnackbar(snackbarData);

        if (!placed) {
            SnackbarManager.snackbars.push(snackbarData);
        }
    }

    public static hideAllSnackbars() {
        SnackbarManager.snackbarInstance.closeSnackbar();
        SnackbarManager.snackbars = [];
    }
}
