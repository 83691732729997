import { autocompleteClasses, styled } from '@mui/material';

interface PopperComponentProps {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        borderBottomLeftRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2,
        fontSize: 14,
        fontWeight: 40
    },

    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.grey[700],
        padding: 0,

        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.grey[600]}`,
            '&:last-child': {
                borderBottom: 'none'
            },

            '&[aria-selected="true"]': {
                backgroundColor: theme.palette.grey[700],
                color: theme.palette.grey[400]
            },

            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
                backgroundColor: theme.palette.grey[800],
            },
        }
    },
}));

export default function PopperComponent(props: PopperComponentProps) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}