import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StackComponent from '../Content/StackComponent';

interface IProps {
    open: boolean;
    onClose: () => void;
}

export default function LogoutDialog(props: IProps) {
    const { open, onClose } = props;

    const navigate = useNavigate();

    function handleLogout() {
        localStorage.clear();
        navigate('/login');
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ fontWeight: '600 !important', fontSize: 18 }}>Weet je zeker dat je wilt uitloggen?</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2 }}>
                    Je zult uit Annie worden uitgelogd! Dat vind ik heel jammer...
                </Box>

                <StackComponent sx={{ justifyContent: 'flex-end' }}>
                    <Button
                        onClick={onClose}
                        sx={{ mr: 2 }}
                        type='submit'
                        variant='contained'
                    >
                        Annuleren
                    </Button>

                    <Button
                        color='error'
                        onClick={handleLogout}
                        variant='contained'
                    >
                        Uitloggen
                    </Button>
                </StackComponent>
            </DialogContent>
        </Dialog>
    );
}
